import React from "react";
import VideoComments from "../../../components/VideoComments";
import Constants from "expo-constants";

const ViewFlatList = (props) => {
  const { login, comments, videoId, categoryId, cardId } = props;

  const { featureCommentsEnabled } = Constants.manifest.extra;

  return (
    <>
      {featureCommentsEnabled && (
        <VideoComments
          login={login}
          comments={comments}
          videoId={videoId}
          categoryId={categoryId}
          cardId={cardId}
        >
        </VideoComments>
      )}
    </>
  );
};

export default ViewFlatList;