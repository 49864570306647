import styled from 'styled-components/native';

export const Container = styled.ScrollView.attrs({
    contentContainerStyle: {
      flexGrow: 1,
      backgroundColor: "#FFFFFF"
    },
  })`
  `;

  export const ViewButton = styled.View`
  padding: 0.5rem 0rem;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const ContentIcon = styled.View`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #3FC380E6;
  border-radius: 50px;
  padding: 5px;
  z-index: 2;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-color: #FFFFFF;
  border-width: 2px;
`;