import { ref, uploadBytesResumable } from "firebase/storage";
import React, { useState, useEffect } from "react";
import { StyleSheet, View, Pressable } from "react-native";
import Button from "../Button";
import THEME from "../../config/theme";
import { storage } from "../../services/firebase";
import { MaterialIcons, FontAwesome, Ionicons } from "@expo/vector-icons";
import {
  Container,
  Content,
  ContentIcon,
  Wrapper,
  ViewPressable,
  ViewSuccess,
} from "./style";
import {
  FooterText,
  SmallText,
  StandardText,
} from "../../config/theme/globalStyles";

import { firestoreAutoId } from "../../utils";
import { getFunctions, httpsCallable } from "firebase/functions";
import { ProgressBar, ActivityIndicator, Provider } from "react-native-paper";
import { AlertBox } from "../../components/AlertBox";

const functions = getFunctions();

export function VideoUpload(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState();
  const [progressPanda, setProgressPanda] = useState();
  const [hoursLeft, setHoursLeft] = useState();
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [error, setError] = useState(null);
  const [uploadCompleted, setUploadCompleted] = useState(false);

  const { onUploadCompleted, onClose, metadata, user } = props;

  const planType = user?.planType;
  let hoursAvailable = 0;

  if (user?.isAdmin && planType === 'premium') {
    hoursAvailable = 150;
  } else if (user?.isAdmin && planType === 'growth') {
    hoursAvailable = 100;
  }
  if (user?.isAdmin && user?.extraHours) {
    hoursAvailable += user.extraHours;
  }

  const getTotalLengthPanda = async () => {
    try {
      setIsLoading(true);
      const getVideoHoursFromPanda = httpsCallable(
        functions,
        "getVideoHoursFromPanda"
      );

      let data = { user: user }
      const totalHoursResponse = await getVideoHoursFromPanda(data);
      const totalHoursUsed = totalHoursResponse.data.totalHours;

      if (totalHoursUsed !== null && totalHoursUsed !== undefined) {
        const hoursLeft = (hoursAvailable - totalHoursUsed).toFixed(1);
        const progressPanda = 1 - ((hoursAvailable - totalHoursUsed) / hoursAvailable);
        setHoursLeft(hoursLeft);
        setProgressPanda(progressPanda);
      } else {
        console.log("Failed to get panda video hours");
      }
    } catch (error) {
      console.log("Failed to get panda video hours", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getVideoLength = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.src = URL.createObjectURL(file);
      video.addEventListener('loadedmetadata', () => {
        URL.revokeObjectURL(video.src);
        const duration = video.duration / 3600;
        resolve(duration);
      });
      video.addEventListener('error', (error) => {
        URL.revokeObjectURL(video.src);
        reject(error);
      });
    });
  }

  useEffect(() => {
    getTotalLengthPanda();
  }, []);

  const showAlert = (title, message) => {
    setVisibleAlert(true);
    setError({ title, message })
  };

  const hideAlert = (status) => {
    setVisibleAlert(status);
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    try {
      setUploading(true);
      const firestoreId = firestoreAutoId();
      const file = event.target[0]?.files[0];

      if (!file) {
        showAlert("Erro", "Não há arquivo para ser enviado.");
        throw new Error("No file attached");
      }
      const toLowerCaseType = file?.type.toLowerCase();

      const allowedTypes = ['audio/', 'video/']
      const isAllowed = allowedTypes.some((type) => toLowerCaseType.includes(type));

      if (!isAllowed) {
        setIsLoading(false);
        showAlert(
          "Formato de arquivo incorreto",
          "Selecione arquivos com formato de áudio ou vídeo."
        );
        throw new Error("Incorrect format");
      }

      const duration = await getVideoLength(file);
      if (hoursLeft < duration) {
        showAlert(
          "Aviso",
          "Você não possui horas suficientes de armazenamento para enviar este vídeo/áudio. Fale com o suporte para contratar mais horas."
        );
        throw new Error("storageLimitReached");
      }

      const fileMeta = metadata && { customMetadata: metadata };
      const name = `${firestoreId + file.name}`;
      const storageRef = ref(storage, name);

      const uploadTask = uploadBytesResumable(storageRef, file, fileMeta);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          console.error(error);
          setUploading(false);
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              showAlert(
                "Erro:",
                "O usuário não está autorizado a realizar a ação desejada, verifique suas regras de segurança para garantir que estejam corretas."
              );
              break;
            case "storage/canceled":
              showAlert("Erro:", "	O usuário cancelou a operação.");
              break;
            case "storage/unknown":
              showAlert(
                "Erro:",
                "Ocorreu um erro desconhecido, contate o suporte."
              );
              break;
            case "storage/object-not-found":
              showAlert(
                "Erro:",
                "Nenhum objeto existe na referência desejada."
              );
              break;
          }
        },
        () => {
          onUploadCompleted();
          setUploading(false);
          setUploadCompleted(true);
        }
      );
    } catch (error) {
      setUploading(false);
      console.error(error);
    }
  };

  return isLoading ? (
    <ActivityIndicator
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#000000a0"
      }}
      size="large"
      color={THEME.COLORS.PRIMARY_800}
    />
  ) : (
    <Provider>
      <Container>
        {uploadCompleted ? (
          <ViewSuccess>
            <MaterialIcons
              name="add-task"
              size={30}
              color={THEME.COLORS.TEXT_ABOUT}
            />
            <StandardText padding="1rem">
              Vídeo/Áudio adicionado com sucesso!
            </StandardText>
          </ViewSuccess>
        ) : (
          <>
            <ViewPressable>
              <Pressable onPress={onClose}>
                <ContentIcon>
                  <Ionicons
                    name="close"
                    size={THEME.FONTSIZE.BIG}
                    color={THEME.COLORS.PRIMARY_900}
                  />
                </ContentIcon>
              </Pressable>
            </ViewPressable>
            <Wrapper>
              <Content>
                <ContentIcon>
                  <FontAwesome
                    name="exclamation-triangle"
                    size={24}
                    color="red"
                  />
                  <StandardText padding="0rem 0.5rem" color="red">
                    Atenção!
                  </StandardText>
                </ContentIcon>
                <SmallText>
                  Ao dar o upload de um novo vídeo ou áudio, o anterior (se
                  houver) será removido.
                </SmallText>
                <ContentIcon>
                  <MaterialIcons name="info" size={24} color="red" />
                  <StandardText padding="0rem 0.5rem" color="red">
                    Informações importantes:
                  </StandardText>
                </ContentIcon>
                <SmallText textAlign="flex-start" padding="0.3rem 0rem">
                  Após enviar o vídeo ou áudio, ele será compactado atomaticamente
                  para reduzir o tamanho e melhorar a velocidade de reprodução sem
                  afetar a qualidade. Esse processo poderá demorar até 3 horas, a
                  depender do tamanho, para estar disponível para seu aluno.
                </SmallText>
                <FooterText
                  textAlign="flex-start"
                  padding="0.1rem 0rem"
                  color={THEME.COLORS.TEXT_ABOUT}
                >
                  Os únicos formatos aceitos são .mp4 e .MKV.
                </FooterText>
                <FooterText
                  textAlign="flex-start"
                  padding="0.1rem 0rem"
                  color={THEME.COLORS.TEXT_ABOUT}
                >
                  Caso você precise adicionar um áudio, coloque-o em formato de
                  vídeo com uma imagem de fundo.
                </FooterText>
              </Content>

              <View>
                <ProgressBar
                  progress={progressPanda}
                  color={THEME.COLORS.PRIMARY_900}
                  style={{ height: "1.2rem", borderRadius: 30 }}
                />
                {hoursLeft < 0 ? (
                  <SmallText textAlign="center" padding="0.5rem 0rem">
                    Você atingiu o limite de {hoursAvailable} horas disponíveis. Total de horas armazenadas: {Math.abs(hoursLeft) + hoursAvailable}
                  </SmallText>
                ) : (
                  <SmallText textAlign="center" padding="0.5rem 0rem">
                    Você possui {hoursLeft} hora(s) livre(s) de {hoursAvailable} horas disponíveis.
                  </SmallText>
                )}
                <FooterText
                  textAlign="center"
                  padding="0.1rem 0rem"
                  color={THEME.COLORS.TEXT_ABOUT}
                >
                  Caso necessite contratar mais horas, fale com o suporte.
                </FooterText>
              </View>
              <View style={{ justifyContent: "center", alignItems: "center" }}>
                <form onSubmit={handleUpload}>
                  <input type="file" style={{ margin: "1rem" }}></input>
                  <button type="submit">Enviar</button>
                </form>
              </View>
              <AlertBox
                title={error?.title}
                message={error?.message}
                visible={visibleAlert}
                onClose={hideAlert}
                leftButtonFunction={hideAlert}
                leftButton={"OK"}
              ></AlertBox>
            </Wrapper>
          </>
        )}

        {uploadCompleted && (
          <View>
            <Button onPress={onClose} title="OK" />
          </View>
        )}
        {uploading && (
          <View
            style={[
              StyleSheet.absoluteFill,
              {
                backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                alignItems: "center",
                justifyContent: "center",
              },
            ]}
          >
            <SmallText margin="1rem">Carregando...</SmallText>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <progress value={progress} max="100" />
              <FooterText color={THEME.COLORS.TEXT_ABOUT} margin="0rem 1rem">
                {progress}%
              </FooterText>
            </View>
          </View>
        )}
      </Container>
    </Provider>
  );
}