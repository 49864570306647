import styled from "styled-components/native";

export const Content = styled.View`
  flex-direction: row;
  padding: ${({ padding }) => (padding ? padding : '0')};
  justify-content: ${({ justifyContent }) => (justifyContent)};
  margin: ${({ margin }) => (margin ? margin : '0')};
`;

export const ContentIcon = styled.TouchableOpacity`
  align-items: center;
  width: ${({ width }) => width};
  padding: ${({ padding }) => (padding ? padding : '0')};
  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : '0')};
`;

export const Text = styled.Text`
  color: ${props => `${props.color}`};
  font-size: ${props => `${props.fontSize}`};
  font-family: ${props => `${props.fontFamily}`};
  margin-top: 1rem;
`;