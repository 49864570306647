import React, { useEffect, useState } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { AppRoutes } from "./AppRoutes";
import { AdminRoutes } from "./AdminRoutes";
import LinkingConfiguration from "./LinkingConfiguration";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { onAuthStateChanged, signInAnonymously} from "firebase/auth";
import { auth, firestore } from "../services/firebase";
import { doc, getDoc } from "firebase/firestore";
import { ActivityIndicator } from "react-native-paper";
import THEME from '../config/theme';

const { Screen, Navigator } = createNativeStackNavigator();

export default function Navigation() {
  const [user, setUser] = useState();
  const [isLoading, setLoading] = useState(true);

  const getUserInformation = async (userId) => {
    const docRef = doc(firestore, "users", userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setUser(docSnap.data());
    }
    setLoading(false)
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user && !user.isAnonymous) {
        getUserInformation(user.uid)
      } else if (!user) {  
        // Nenhum usuário está logado, realizar login anônimo.
       signInAnonymously(auth)
       .then(() => {
        setLoading(false);
       // Login anônimo realizado com sucesso.
       })
       .catch((error) => {
       // Houve um erro ao realizar login anônimo.
       console.error(error);
       });
         }
      else {
        setLoading(false);
      }
    });
  }, []);

  return isLoading ? (
    <ActivityIndicator
      style={{
        flex: 1,
        backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
        justifyContent: "center",
      }}
      color={THEME.COLORS.PRIMARY_800}
    />
  ) : (
    <NavigationContainer linking={LinkingConfiguration}>
      {user?.isAdmin ? (
        <Navigator screenOptions={{ headerShown: false }}>
          <Screen name="Painel" component={AdminRoutes} />
        </Navigator>
      ) : (
        <AppRoutes />
      )}
    </NavigationContainer>
  )
}