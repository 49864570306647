import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import THEME from '../../config/theme';
import Button from '../Button';
import { View } from 'react-native';
import { Title, SmallText, SubTitle } from '../../config/theme/globalStyles';
import { AlertBox } from "../../components/AlertBox";
import { useNavigation } from "@react-navigation/native";
import { Provider } from "react-native-paper";

const Table = ({ tableData, user }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [visibleAlert, setVisibleAlert] = useState(false);
    const [title, setTitle] = useState(null);
    const [message, setMessege] = useState(null);

    const totalUsers = tableData.length;
    const totalActiveSubscribers = tableData.filter(row => row.planTitle && row.plan !== "0").length;
    const totalUnpaidSubscriptions = tableData.filter(row => row.planTitle && row.plan == "0").length;

    const navigation = useNavigation();

    const showAlert = (title, message) => {
        setVisibleAlert(true);
        setTitle(title);
        setMessege(message);
    };

    const hideAlert = (status) => {
        setVisibleAlert(status);
    };

    const columns = [
        {
            name: 'Nome',
            selector: row => row.Nome_Completo,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.Email,
            sortable: true,
        },
        {
            name: 'Celular',
            selector: row => row.Celular,
            sortable: true,
        },
        {
            name: 'CPF',
            selector: row => row?.CPF,
            sortable: true,
        },
        {
            name: 'Plano',
            selector: row => row.planTitle,
            sortable: true,
        },
        {
            name: 'Status Plano',
            selector: row => row.planTitle && row.plan == "0" ? 'Não Pago' : row.planTitle && 'Ativo',
            sortable: true,
        },
        {
            name: 'Cursos',
            selector: row => row.coursesTitle,
            sortable: true,
        },
        {
            name: 'Vídeos assistidos',
            selector: row => row.watchedVideos.length,
            cell: row => (
                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                    <SmallText>{row.watchedVideos.length}</SmallText>
                    {row.watchedVideos.length > 0 && (
                        <Button
                            title={"Ver"}
                            colorbutton={"#767577"}
                            colortitle={"#FFFFFF"}
                            fontFamily={THEME.FONTFAMILY.LIGHT}
                            fontSize={THEME.FONTSIZE.EXTRASMALL}
                            onPress={() => {
                                user?.planType == "premium" ?
                                    navigation.navigate("UsersAnalytics", {
                                        userId: row.id,
                                        userName: row.Nome_Completo,
                                    })
                                    :
                                    showAlert("Disponível somente no plano PREMIUM", "Para visualizar quais vídeos seus alunos estão assistindo, contate o suporte para fazer o upgrade do seu plano.")
                            }
                            }
                        />
                    )}
                </View>
            ),
            sortable: true,
        },
    ];

    const customStyles = {
        rows: {
            style: {
                color: "#000000",
                fontFamily: THEME.FONTFAMILY.LIGHT,
                fontSize: THEME.FONTSIZE.EXTRASMALL
            },
        },
        headCells: {
            style: {
                color: "#000000",
                fontFamily: THEME.FONTFAMILY.MEDIUM,
                fontSize: THEME.FONTSIZE.EXTRASMALL
            },
        },
        cells: {
            style: {
            },
        },
    };

    const handleDownloadCSV = () => {
        // Crie um array de objetos contendo apenas os dados das linhas selecionadas
        const selectedData = selectedRows.map(row => {
            // Retorne um objeto com as propriedades desejadas, por exemplo:
            return {
                Nome_Completo: row.Nome_Completo,
                Email: row.Email,
                Celular: row.Celular,
                CPF: row?.CPF,
                Plano: row.planTitle,
                StatusPlano: row.planTitle && row.plan == "0" ? 'Não Pago' : row.planTitle && 'Ativo',
                Cursos: row.coursesTitle,
                Vídeos_Assistidos: row.watchedVideos.length
            }
        });

        // Crie o conteúdo CSV a partir dos objetos selecionados
        const csvContent = "data:text/csv;charset=utf-8," + selectedData
            .map(row => Object.values(row).join(','))
            .join('\n');

        // Crie um link de download e clique nele para iniciar o download
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'selected_data.csv');
        document.body.appendChild(link);
        link.click();
    };

    return (
        <Provider>
            <View style={{ flexDirection: "row", padding: "1rem", alignItems: "center", justifyContent: "space-between" }}>
                <Title textAlign="left">Usuários:</Title>
                <Button onPress={() => handleDownloadCSV()} title={"Baixar CSV"} fontFamily={THEME.FONTFAMILY.LIGHT} fontSize={THEME.FONTSIZE.EXTRASMALL}></Button>
            </View>
            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-evenly", padding: "1rem" }}>
                <View style={{
                    backgroundColor: "#f0f0f0",
                    borderRadius: 10,
                    padding: 10,
                }}>
                    <SubTitle>Total de usuários</SubTitle>
                    <Title>{totalUsers}</Title>
                </View>
                <View style={{
                    backgroundColor: "#f0f0f0",
                    borderRadius: 10,
                    padding: 10,
                }}>
                    <SubTitle>Assinantes ativos</SubTitle>
                    <Title>{totalActiveSubscribers}</Title>
                </View>
                <View style={{
                    backgroundColor: "#f0f0f0",
                    borderRadius: 10,
                    padding: 10,
                }}>
                    <SubTitle>Não Pagos</SubTitle>
                    <Title>{totalUnpaidSubscriptions}</Title>
                </View>
            </View>
            <DataTable
                columns={columns}
                data={tableData}
                highlightOnHover
                pointerOnHover
                selectableRows
                selectableRowsHighlight
                selectableRowsVisibleOnly
                customStyles={customStyles}
                onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
            />
            {visibleAlert && (
                <AlertBox
                    title={title}
                    message={message}
                    visible={visibleAlert}
                    onClose={hideAlert}
                ></AlertBox>
            )}
        </Provider>
    );
}

export default Table;