import React, { useEffect, useState, useCallback } from "react";
import { SmallText } from "../../config/theme/globalStyles";
import { Container, Content, Avatar, ContentIcon, ViewIcon } from "./style";
import { Feather, Ionicons, AntDesign } from "@expo/vector-icons";
import THEME from "../../config/theme";
import { FooterText } from "../../config/theme/globalStyles";
import { View, TouchableOpacity } from "react-native";
import { firestore } from "../../services/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { ActivityIndicator } from "react-native-paper";

const PlayList = ({ user, favorites, docId, id, cardId, categoryId, title, image, backgroundImage, videoInfo, pandaVideoMetadata, link, url, pdf, meetingNumber, handleRemoveLike }) => {
  const pdfUrl = pdf?.url || pdf;
  const src =
    videoInfo?.url ||
    pandaVideoMetadata?.video_player ||
    link ||
    "";

  const [isRemoved, setIsRemoved] = useState(false);
  const [watched, setWatched] = useState(null);


  const loadDetail = useCallback(async () => {
    try {
      const videoId = id ? id : docId;
      const userId = user?.uid ? user?.uid : user?.id;

      const watchedDocRef = doc(firestore, "users", userId, "contents", videoId);

      onSnapshot(watchedDocRef, (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          setWatched(data?.isWatched === true ? true : false);
        } else {
          setWatched(false);
        }
      });
    } catch (error) {
      console.log(error);
      setWatched(false);
    }
  }, [user?.id, id]);

  useEffect(() => {
    loadDetail();
  }, []);



  const Icon = () => {

    return (
      <ContentIcon>
        {src ? (
          <>
            {watched === true ?
              <View style={{ paddingRight: "0.5rem" }}>
                <AntDesign name="checkcircle" size={14} color={THEME.COLORS.PRIMARY_900} />
              </View>
              : watched === false ?
                <ViewIcon>
                  <Ionicons
                    name="play"
                    size={9}
                    color={THEME.COLORS.BACKGROUND_MAIN}
                  />
                </ViewIcon> :
                <ViewIcon backgroundColor="transparent">
                  <ActivityIndicator color={THEME.COLORS.TEXT_MAIN} />
                </ViewIcon>
            }
            <FooterText color={THEME.COLORS.TEXT_MAIN} fontSize={10}>Video</FooterText>
          </>
        ) : meetingNumber ? (
          <>
            {watched === true ?
              <View style={{ paddingRight: "0.5rem" }}>
                <AntDesign name="checkcircle" size={14} color={THEME.COLORS.PRIMARY_900} />
              </View>
              : watched === false ?
                <ViewIcon>
                  <Feather
                    name="radio"
                    size={9}
                    color={THEME.COLORS.BACKGROUND_MAIN}
                  />
                </ViewIcon> :
                <ViewIcon backgroundColor="transparent">
                  <ActivityIndicator color={THEME.COLORS.TEXT_MAIN} />
                </ViewIcon>
            }
            <FooterText color={THEME.COLORS.TEXT_MAIN} fontSize={10}>Ao vivo</FooterText>
          </>
        ) : pdfUrl ? (
          <>
            {watched === true ?
              <View style={{ paddingRight: "0.5rem" }}>
                <AntDesign name="checkcircle" size={14} color={THEME.COLORS.PRIMARY_900} />
              </View>
              : watched === false ?
                <ViewIcon>
                  <Ionicons
                    name="document-text-outline"
                    size={9}
                    color={THEME.COLORS.BACKGROUND_MAIN}
                  />
                </ViewIcon> :
                <ViewIcon backgroundColor="transparent">
                  <ActivityIndicator color={THEME.COLORS.TEXT_MAIN} />
                </ViewIcon>
            }
            <FooterText color={THEME.COLORS.TEXT_MAIN} fontSize={10}>Arquivo</FooterText>
          </>
        ) : url ? (
          <>
            <ViewIcon>
              <Feather
                name="link"
                size={9}
                color={THEME.COLORS.BACKGROUND_MAIN}
              />
            </ViewIcon>
            <FooterText color={THEME.COLORS.TEXT_MAIN} fontSize={10}>Link</FooterText>
          </>
        ) : <></>}
      </ContentIcon>
    );
  };

  const XIcon = () => {
    if (isRemoved) {
      return null;
    }

    return !isRemoved && (
      <TouchableOpacity onPress={() => {
        setIsRemoved(true);
        handleRemoveLike(id, cardId, categoryId);
      }}>
        <Feather
          name="x"
          size={15}
          color={THEME.COLORS.TEXT_MAIN}
        />
      </TouchableOpacity>
    )

  }

  if (isRemoved) {
    return null;
  }

  return (
    <Container>
      <Avatar resizeMode="cover" source={{ uri: backgroundImage?.url || image }} />
      <View style={{ flex: 1, paddingHorizontal: "0.5rem" }}>
        <Content>
          <View style={{ width: favorites ? "90%" : "100%" }}>
            <SmallText
              color={THEME.COLORS.TEXT_MAIN}
              textAlign="left"
              numberOfLines={2}
            >
              {title}
            </SmallText>
          </View>
          {favorites &&
            <View style={{ width: "10%", alignItems: "center" }}>
              <XIcon></XIcon>
            </View>
          }
        </Content>
        <ContentIcon>
          <Icon />
        </ContentIcon>
      </View>
    </Container>
  )
};

export default PlayList;
