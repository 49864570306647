import styled from "styled-components/native";
import THEME from "../../../config/theme";


export const ViewSectionAdmin = styled.View`
 padding: 1rem 0rem;
 flex-basis: auto;
 background-color: ${THEME.COLORS.BACKGROUND_ABOUT};
`;


export const ViewButton = styled.View`
 padding: 0.5rem 0rem;
 align-items: center;
 justify-content: center;
 flex-direction: row;
`;


export const ViewTextInput = styled.View`
 `;

export const ViewRow = styled.View`
flex-direction: row;
align-items: center;
 `;


export const ViewDataTable = styled.View`
 padding-horizontal: 30px;
`;


export const ViewModal = styled.ScrollView.attrs({
  showsVerticalScrollIndicator: true,
  contentContainerStyle: {
    alignItems: "center",
    padding: "2rem",
    backgroundColor: "#FFFFFF",
    flexGrow: 1,
    justifyContent: "flex-start"
  },
})` 
`;


export const ViewSwitch = styled.View`
 align-items: center;
 justify-content: center;
 flex-direction: row;
`;




export const ViewDescription = styled.View`
 justify-content: center;
 padding: 1rem 0rem 0rem 0rem;
`;


export const ContentIcon = styled.View`
 position: absolute;
 bottom: 20px;
 right: 20px;
 background-color: #3FC380E6;
 border-radius: 50px;
 padding: 5px;
 z-index: 99999;
 width: 50px;
 height: 50px;
 justify-content: center;
 align-items: center;
 border-color: #FFFFFF;
 border-width: 2px;
`;


export const Container = styled.ScrollView.attrs({
  contentContainerStyle: {
    flexGrow: 1,
    backgroundColor: "#F2F2F3"
  },
})`
 `;