import React, { useState, useEffect, useCallback } from "react";
import { View, Modal, TouchableOpacity, ScrollView, Dimensions, Linking } from "react-native";
import { Image, ImageBlocked } from "../style";
import useViewport from "../../../hooks/useViewport";
import THEME from "../../../config/theme";
import Button from "../../../components/Button";
import TouchableText from "../../../components/TouchableText";
import { firestore } from "../../../services/firebase";
import { doc, getDoc, collection, where, query, getDocs } from "firebase/firestore";
import { A } from '@expo/html-elements';
import { SubTitle, StandardText, SmallText, FooterText } from "../../../config/theme/globalStyles";

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const ViewVideo = (props) => {

  const { width } = useViewport();
  const breakpoint = 1080;

  const { user, card, navigation, guruUserToken } = props;
  const { plans, coursesCard, img, backgroundImage, productIds } = card || {};
  const { plan: userPlan, courses: userCourses, productIds: userProductIds, isAdmin } = user || {};

  const image = backgroundImage?.url || img;

  const [modalVisible, setModalVisible] = useState(false);
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [products, setProducts] = useState([]);

  const fetchAndFilterPlans = useCallback(async () => {
    if (plans) {
      const cardPlansIds = plans.map((plan) => plan.id);
      const plansRef = collection(firestore, "plans");
      const plansQuery = query(plansRef, where("archived", "!=", true));
      const plansSnapshot = await getDocs(plansQuery);
      const filteredPlans = plansSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((plan) => cardPlansIds.includes(plan.id));
      setFilteredPlans(filteredPlans);
    }
  }, [plans]);

  const fetchAndFilterCourses = useCallback(async () => {
    if (coursesCard) {
      const cardCoursesIds = coursesCard.map((course) => course.id);
      const coursesRef = collection(firestore, "courses");
      const coursesQuery = query(coursesRef, where("archived", "!=", true));
      const coursesSnapshot = await getDocs(coursesQuery);
      const filteredCourses = coursesSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((course) => cardCoursesIds.includes(course.id));
      setFilteredCourses(filteredCourses);
    }
  }, [coursesCard]);

  useEffect(() => {
    fetchAndFilterPlans();
    fetchAndFilterCourses();
  }, [fetchAndFilterPlans, fetchAndFilterCourses]);

  const showOptionsModal = useCallback(async () => {
    setModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const fetchProducts = useCallback(async () => {
    if (productIds) {
      const fetchedProducts = [];
      const productsRef = collection(firestore, 'products');
      for (const productId of productIds) {
        const q = query(productsRef, where('productId', '==', productId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          if (!doc.data().archived) {
            fetchedProducts.push({ id: doc.id, ...doc.data() });
          }
        });
      }
      setProducts(fetchedProducts);
    }
  }, [productIds]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const newRenderOptionsModal = () => {

    const modalWidth = Math.min(windowWidth - 40, 700);

    const cardMinWidth = 300; // Defina a largura mínima desejada do card
    const cardMaxWidth = Math.min(windowWidth - 40, 400); // Largura máxima do card

    const cardWidth = width > cardMaxWidth ? cardMaxWidth : cardMinWidth; // Define a largura do card com base na largura da tela

    const handleCheckout = (url) => {
      let checkoutUrlWithParams = url;

      if (user?.Nome_Completo !== undefined) {
        checkoutUrlWithParams += `?name=${encodeURIComponent(user.Nome_Completo)}`;
      }
      if (user?.Email !== undefined) {
        checkoutUrlWithParams += `&email=${encodeURIComponent(user.Email)}`;
      }
      if (user?.Celular !== undefined) {
        checkoutUrlWithParams += `&phone_number=${encodeURIComponent(user.Celular)}`;
      }
      if (user?.Doc !== undefined) {
        checkoutUrlWithParams += `&doc=${encodeURIComponent(user.Doc)}`;
      }
      Linking.openURL(checkoutUrlWithParams);
    };

    return (
      <Modal
        visible={modalVisible}
        transparent={true}
        animationType="fade"
      >
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <View style={{ maxWidth: modalWidth, backgroundColor: THEME.COLORS.BACKGROUND_ABOUT, maxHeight: windowHeight * 0.7, borderRadius: 10, padding: 5, alignItems: 'center' }}>
            <ScrollView
              contentContainerStyle={{
                flexGrow: 1,
                paddingHorizontal: 15,
                paddingBottom: 60,
              }}
              style={{ width: '100%' }}
            >
              <Image
                source={require('../../../../assets/LogoLogin.png')}
                style={{ height: 180, width: Math.min(windowWidth - 40, 180), alignSelf: 'center' }}
                resizeMode="contain"
              />
              {products.length === 0 ? (
                <SmallText fontFamily={THEME.FONTFAMILY.LIGHT} margin="1rem 0rem">Este conteúdo não está disponível para compra.</SmallText>
              ) : (
                <>
                  <SubTitle fontFamily={THEME.FONTFAMILY.BOLD}>QUER TER ACESSO A ESTE CONTEÚDO EXCLUSIVO?</SubTitle>
                  <SmallText fontFamily={THEME.FONTFAMILY.LIGHT} margin="1rem 0rem">Escolha uma das opções abaixo:</SmallText>
                  {products.map((product, index) => (
                    <TouchableOpacity key={index} style={{ width: cardWidth, maxWidth: '100%', borderWidth: 1, borderColor: 'lightgray', borderRadius: 10, padding: 10, marginBottom: 10, alignSelf: 'center' }}>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                        <StandardText fontFamily={THEME.FONTFAMILY.BOLD}>{product?.name}</StandardText>
                      </View>
                      <View style={{ flex: 1 }}>
                        <FooterText textAlign="left" color={THEME.COLORS.TEXT_ABOUT}>{product?.description}</FooterText>
                      </View>
                      <TouchableOpacity onPress={() => handleCheckout(product?.checkoutUrl)} style={{ backgroundColor: THEME.COLORS.PRIMARY_900, borderRadius: 5, padding: 10, marginTop: 5 }}>
                        <FooterText color={THEME.COLORS.TEXT_BUTTON}>{product?.hasExpirationDate === false ? 'Assine agora' : 'Compre agora'}</FooterText>
                      </TouchableOpacity>
                    </TouchableOpacity>
                  ))}
                </>
              )}
            </ScrollView>
            <TouchableOpacity onPress={closeModal} style={{ position: 'absolute', top: 10, right: 30 }}>
              <StandardText >X</StandardText >
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    );
  };

  const renderOptionsModal = () => {
    return (
      <Modal
        visible={modalVisible}
        animationType="slide"
        transparent={true}
        onRequestClose={closeModal}
      >
        <View style={{
          width: width < breakpoint ? "90%" : undefined,
          backgroundColor: '#FFFFFF',
          borderRadius: 10,
          padding: 20,
          alignSelf: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: width < breakpoint ? '3.5rem' : 'auto',
          marginBottom: 'auto',
        }
        }>
          <TouchableOpacity
            onPress={closeModal}
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
            }}>
            <StandardText color={THEME.COLORS.PRIMARY_900}>X</StandardText>
          </TouchableOpacity>
          {filteredPlans.length == 0 && filteredCourses.length == 0 && (
            <SmallText color="#000000" padding="1rem">Conteúdo não está disponível para compra.</SmallText>
          )}
          {filteredPlans && filteredPlans.length !== 0 && (
            <>
              <StandardText color="#000000" padding="1rem">Quer ter acesso a esse conteúdo exclusivo?</StandardText>
              <SmallText color="#000000" padding="1rem">Escolha uma das opções abaixo</SmallText>
              {filteredPlans.map((plan) => (
                <TouchableOpacity
                  key={plan.id}
                  onPress={async () => {
                    closeModal();

                    const docRef = doc(firestore, "plans", plan.id);
                    const docSnap = await getDoc(docRef);

                    if (!docSnap.exists()) return;

                    if (docSnap.data()?.hasOutsidePage && docSnap.data()?.salesPageUrl) {
                      const anchor = document.createElement('a');
                      anchor.href = docSnap.data()?.salesPageUrl;
                      anchor.target = '_self';
                      anchor.click();
                    } else {
                      navigation.navigate("Planos", {
                        userId: user?.id,
                        plan: user?.plan,
                        firstPlanPurchase: user?.firstPlanPurchase,
                        isAdmin: user?.isAdmin === true ? true : false
                      });
                    }
                  }}
                  style={{
                    padding: 10,
                    borderRadius: 10,
                    backgroundColor: "#d3d3d3",
                    marginBottom: 8
                  }}
                >
                  <A
                    href={null}
                    style={{ color: '#000000', fontSize: THEME.FONTSIZE.MEDIUM }}
                  >
                    {plan.title}
                  </A>
                </TouchableOpacity>
              ))}
            </>
          )}
          {filteredCourses && filteredCourses.length !== 0 && (
            <>
              <SmallText color="#000000" padding="1rem">Selecione um produto para ter acesso a esse conteúdo:</SmallText>
              {filteredCourses.map((course) => (
                <TouchableOpacity
                  key={course.id}
                  onPress={async () => {
                    closeModal();

                    const docRef = doc(firestore, "courses", course.id);
                    const docSnap = await getDoc(docRef);

                    if (!docSnap.exists()) return;

                    if (docSnap.data()?.hasOutsidePage && docSnap.data()?.salesPageUrl) {
                      const anchor = document.createElement('a');
                      anchor.href = docSnap.data()?.salesPageUrl;
                      anchor.target = '_self';
                      anchor.click();
                    } else {
                      navigation.navigate("Produto", {
                        courseId: course.id,
                      });
                    }
                  }}
                  style={{
                    padding: 10,
                    borderRadius: 10,
                    backgroundColor: "#d3d3d3",
                    marginBottom: 8
                  }}
                >
                  <A
                    href={null}
                    style={{ color: '#000000', fontSize: THEME.FONTSIZE.MEDIUM }}
                  >
                    {course.title}
                  </A>
                </TouchableOpacity>
              ))}
            </>
          )}
        </View>
      </Modal >
    );
  };

  const toDate = (seconds) => {
    const date = new Date(1970, 0, 1);
    date.setSeconds(seconds);
    return date;
  };

  const content = (
    <>
      {(productIds &&
        productIds?.length > 0 &&
        productIds?.filter(cardProductId => {
          const matchingUserProducts = userProductIds?.filter(userProduct => userProduct.productId === cardProductId);
          if (!matchingUserProducts || matchingUserProducts.length === 0) {
            return false; // O usuário não possui este produto, então não tem acesso
          }
          // Se não houver data de expiração, ou se alguma data de expiração for maior que a data atual, o usuário tem acesso
          return matchingUserProducts.some(userProduct => !userProduct.expirationDate || toDate(userProduct.expirationDate.seconds) > new Date());
        }).length > 0
      ) ||
        (plans &&
          plans.length > 0 &&
          plans.filter((plan) => (plan.priceId || plan.productId) == userPlan).length > 0) ||
        (userCourses &&
          userCourses.length > 0 &&
          userCourses.filter((course) => coursesCard?.some((itemToBeRemoved) =>
            (itemToBeRemoved.priceId || itemToBeRemoved.productId) === (course.priceId || course.productId)) && toDate(course.dueDate.seconds) > new Date()
          ).length > 0) ||
        (plans == null && coursesCard == null && productIds == null) || isAdmin === true ? (
        <Image
          source={{ uri: image }}
          resizeMode="cover">
          {" "}
        </Image>
      ) : (
        <Image
          source={{ uri: image }}
          resizeMode="cover"
        >
          {guruUserToken && productIds  ? newRenderOptionsModal() : renderOptionsModal()}
          <ImageBlocked>
            {(userPlan && plans && coursesCard == null) || (userProductIds && productIds && coursesCard == null) ? (
              <Button
                title={userPlan && plans && coursesCard == null ? "ALTERAR PLANO" : "COMPRAR AGORA"}
                onPress={() => userPlan && plans && coursesCard == null ? navigation.navigate("Meu Perfil") : showOptionsModal()}
                borderRadius="5px"
              ></Button>
            ) : user ? (
              <Button
                title={"COMPRAR AGORA"}
                onPress={() => user?.isAdmin ? undefined : showOptionsModal()}
                borderRadius="5px"
              ></Button>
            ) : (
              <>
                <Button
                  title={"COMPRAR AGORA"}
                  onPress={() => user?.isAdmin ? undefined : showOptionsModal()}
                  borderRadius="5px"
                ></Button>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    margin: "1rem",
                  }}
                >
                  <StandardText
                    margin="0rem 0.2rem"
                    color="#FFFFFF"
                  >
                    ou
                  </StandardText>
                  <TouchableText
                    textDecoration="underline"
                    onPress={() => navigation.navigate("Login")}
                    title={"Logar"}
                    color="#FFFFFF"
                  ></TouchableText>
                  <StandardText
                    margin="0rem 0.2rem"
                    color="#FFFFFF"
                  >
                    para continuar
                  </StandardText>
                </View>
              </>
            )}
          </ImageBlocked>
        </Image>
      )
      }
    </>
  );


  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        borderRadius: 10,
        overflow: "hidden",
      }}
    >
      {content}
    </View>
  );
};

export default ViewVideo;