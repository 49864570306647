import React from 'react';
import { Container, Content } from './style';
import { Text } from 'react-native';

const Privacy = (props) => {
    return (
        <Container>
            {props.privacyData.map((element, index) => (
                <Content key={index}>
                    <Text style={{ color:"#454247", fontSize: 25, fontWeight: 'bold', marginBottom: "1rem" }}>{element.subtitle}</Text>
                    {element.paragraphs.map((item, subIndex) => (
                        <Text key={subIndex} style={{ color:"#454247", fontSize: 20, margin: "1rem 0rem 0.5rem 0rem", textAlign: "justify" }} >{item.text}</Text>
                    ))}
                </Content>
            ))}
        </Container>
    );
};

export default Privacy;