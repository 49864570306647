export const clientNameFooter = "FIVE PERFORMANCE";
export const titleMain = "Seja bem-vindo!";
export const subtitleMain = "Treinamento de alto rendimento no ciclismo e no triathlon";

export const aspectRatioLogoAbout = '1'; // width/height
export const aspectRatioLogoMain = '1'; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "5ways Sports Consulting Ltda";
export const nomeFornecedor = "5ways Sports Consulting";
export const artigo = "o";
export const artigoCaps = "O";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://www.instagram.com/five.performance",
    profile: "@five.performance"
  },
  {
    type: "material-community",
    name: "whatsapp",
    link: "https://api.whatsapp.com/send?phone=5511945128571",
    number: "(11) 94512-8571"
  },
];

export const drawerClosedLinks = [
  {
    title: "Entre em contato",
    link: "https://api.whatsapp.com/send?phone=5511945128571",
  },
];

export const linkAppleStore = "https://apps.apple.com/br/app/five-performance-training/id1672342787"
export const linkPlayStore = "https://play.google.com/store/apps/details?id=app.web.mobile5ways_sports_consulting.twa"

export const hasOutsideAboutPage = false;
export const drawerOpenedLinks = [];