import React, { useState, useEffect } from "react";
import { View, TextInput, Pressable, FlatList } from "react-native";
import { Feather, FontAwesome } from "@expo/vector-icons";
import { auth } from '../../services/firebase'
import { onAuthStateChanged, signInAnonymously } from "firebase/auth";
import CommentItem from "./CommentItem";
import { ContainerMobile, ContainerDesktop, Content } from "./style";
import { collection, addDoc } from "firebase/firestore";
import { firestore } from "../../services/firebase";
import { StandardText, FooterText } from "../../config/theme/globalStyles";
import THEME from "../../config/theme";
import useViewport from "../../hooks/useViewport";
import { ActivityIndicator } from "react-native";

const VideoComments = ({ login, comments, videoId, categoryId, cardId }) => {

  const { width } = useViewport();
  const breakpoint = 1080;
  const Container = width < breakpoint ? ContainerMobile : ContainerDesktop;

  const [user, setUser] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [newComment, setNewComment] = useState("");
  const [newReply, setNewReply] = useState("");
  const [isReply, setIsReply] = useState(false);
  const [commentId, setCommentId] = useState("");

  const [commentPlaceholder, setCommentPlaceholder] = useState("Faça um comentário");

  const changeCommentPlaceholder = (text, value) => { setCommentPlaceholder(text), setIsReply(true), setCommentId(value) };
  const changeLoading = () => setIsLoading(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user && !user.isAnonymous) {
        setUser(user)
      } else if(!user) {  
        // Nenhum usuário está logado, realizar login anônimo.
       signInAnonymously(auth)
       .then(() => {
       // Login anônimo realizado com sucesso.
       console.log('Usuário logado anonimamente');
       })
       .catch((error) => {
       // Houve um erro ao realizar login anônimo.
       console.error(error);
       });
      }
    });
    if (comments == null) {
      setIsLoading(false)
    }
  }, []);

  const sendComment = async () => {

    if (!user) {
      console.error("User not found");
      return;
    }
    let date = new Date();

    await addDoc(
      collection(
        firestore,
        "categories",
        categoryId,
        "cards",
        cardId,
        "videos",
        videoId,
        "comments"
      ),
      {
        comment: newComment,
        userId: user.uid,
        createdAt: date,
      }
    );
    setNewComment("");
  };

  const sendReply = async (commentId) => {
    if (!user) return;

    let date = new Date();
    await addDoc(
      collection(
        firestore,
        "categories",
        categoryId,
        "cards",
        cardId,
        "videos",
        videoId,
        "comments",
        commentId,
        "replies"
      ),
      {
        comment: newReply,
        userId: user.uid,
        createdAt: date,
      }
    );
    setNewReply("");
  };

  const ItemSeparatorView = () => {
    return (
      <View
        style={{ height: 0.5, width: '100%', backgroundColor: THEME.COLORS.TEXT_MAIN }}
      />
    );
  };

  return (
    <Container>
      <StandardText
        color={THEME.COLORS.TEXT_MAIN}
        textAlign="left">
        Comentários
      </StandardText>
      {comments !== null ? (
        <>
          {isLoading && (
            <ActivityIndicator
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                alignItems: 'center',
                justifyContent: 'center'
              }}
              color={THEME.COLORS.PRIMARY_800} />
          )}
          <FlatList
            data={comments}
            ItemSeparatorComponent={isLoading ? null : ItemSeparatorView}
            renderItem={({ item, index }) => (
              <CommentItem login={login} comment={item} editPlaceholder={changeCommentPlaceholder} setIsLoading={changeLoading} />
            )}
          />
        </>
      ) : (
        <View style={{ flex: 1, justifyContent: "center" }}>
          <FooterText
            color={THEME.COLORS.TEXT_MAIN}>Não há comentários</FooterText>
        </View>
      )}
      < Content >
        {!isReply && user ? (
          <>
            <Feather
              name="user"
              size={22}
              color={THEME.COLORS.TEXT_MAIN}
            />
            <TextInput
              placeholder={commentPlaceholder}
              value={newComment}
              onChangeText={setNewComment}
              placeholderTextColor="#7F7F7F"
              style={{
                backgroundColor: "#DBDBDB",
                color: "#7F7F7F",
                padding: 10,
                flex: 1,
                margin: 10,
                borderRadius: 5
              }}
            />
            <Pressable onPress={sendComment}>
              <FontAwesome
                name="send-o"
                size={18}
                color="white"
                style={{backgroundColor:THEME.COLORS.PRIMARY_900 , padding: "0.4rem", borderRadius: "5px"}}
              />
            </Pressable>
          </>
        ) : isReply && user && (
          <>
            <Feather name="user" size={22} color={THEME.COLORS.TEXT_MAIN} />
            <TextInput
              placeholder={commentPlaceholder}
              value={newReply}
              onChangeText={setNewReply}
              placeholderTextColor="#7F7F7F"
              style={{
                backgroundColor: "#DBDBDB",
                color: "#7F7F7F",
                padding: 10,
                flex: 1,
                margin: 10,
                borderRadius: 5
              }}
            />
            <Pressable onPress={() => sendReply(commentId)}>
              <FontAwesome name="send-o" size={22} color="white" />
            </Pressable>
          </>
        )}
      </Content>
    </Container >
  );
};

export default VideoComments;