import React, { useState, useEffect } from "react";
import { View, FlatList } from "react-native";
import { doc, getDoc } from "firebase/firestore";
import THEME from "../../../config/theme";
import { Feather } from '@expo/vector-icons';
import { firestore } from "../../../services/firebase";
import { Avatar, ViewComment, ViewAvatar } from "../style";
import LogoMain from "../../../../assets/LogoMain.png"
import { aspectRatioLogoMain } from "../../../config/data";
import ReplyItem from "../ReplyItem";
import { TouchableOpacity } from "react-native-gesture-handler";
import { FooterText } from "../../../config/theme/globalStyles";

const CommentItem = ({ login, comment, editPlaceholder, setIsLoading }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [commentCreatedAt, setCommentCreatedAt] = useState(null);

  const { isAdmin }= user || false

  const fetchUser = async () => {
    const docRef = doc(firestore, "users", comment.userId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) return;
    setUser(docSnap.data())
    setIsLoading()
    setLoading(false)
  }

  useEffect(() => {
    fetchUser();
    setCommentCreatedAt(timeAgo(new Date(comment.createdAt.toDate().toString())))
  }, []);

  const timeAgo = (date) => {
    const seconds = Math.floor((new Date() - date) / 1000);

    let interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return 'há ' + interval + ' anos';
    }

    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return 'há ' + interval + ' meses';
    }

    interval = Math.floor(seconds / 86400);

    if (interval > 1) {
      return 'há ' + interval + ' dias';
    }

    interval = Math.floor(seconds / 3600);

    if (interval > 1) {
      return 'há ' + interval + ' horas';
    }

    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return 'há ' + interval + ' minutos';
    }

    if (seconds < 10) return 'agora';

    return 'há ' + Math.floor(seconds) + ' segundos';
  };

  return !loading && (
    <ViewComment>
      <ViewAvatar>
      {isAdmin ? (
          <Avatar
            resizeMode="contain"
            width={aspectRatioLogoMain * 48}
            source={LogoMain}
          />
        ) : (
          <Feather name="user" size={24} color={THEME.COLORS.PRIMARY_900} />
        )}
      </ViewAvatar>
      <View style={{ flex: 1, paddingLeft:5}}>
        <View flexDirection="row">
          <FooterText color={THEME.COLORS.TITLE_MAIN} textAlign="left" fontFamily={THEME.FONTFAMILY.REGULAR}>
            {user?.Nome_Completo}
          </FooterText>
        </View>
        <View>
          <FooterText color={THEME.COLORS.TEXT_MAIN} textAlign="left">
            {comment.comment}
          </FooterText>
          <View style={{ flexDirection: "row", paddingTop: "0.4rem" }}>
            <FooterText
              textAlign="left"
              color={THEME.COLORS.TEXT_MAIN}>
              {commentCreatedAt}
            </FooterText>
            {login && (
            <TouchableOpacity
            style={{ paddingLeft: "1rem" }}
            onPress={() => editPlaceholder(`Responder ${user?.Nome_Completo}`, comment.id)}>
            <FooterText
              textAlign="left"
              color={THEME.COLORS.TEXT_MAIN}>
              Responder
            </FooterText>
          </TouchableOpacity>
            )}
          </View>
        </View>
        {comment.replies && (
          <FlatList
            data={comment.replies}
            renderItem={({ item, index }) => (
              <ReplyItem reply={item} />
            )}
            style={{ flexGrow: 0 }}
          />
        )}
      </View>
    </ViewComment>
  );
};

export default CommentItem;