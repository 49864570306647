import React, { useState } from 'react';
import { ActivityIndicator } from "react-native-paper";
import { View, TextInput, Pressable } from 'react-native';
import THEME from '../../config/theme';
import { Feather, Octicons } from '@expo/vector-icons';
import CategoryList from '../CategoryList';
import { firestore } from "../../services/firebase"
import { getDocs, collection } from "firebase/firestore";
import { FooterText, StandardText } from '../../config/theme/globalStyles';

const SearchBar = () => {
    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState({ cardsResults: [], videosResults: [] });
    const [searchPerformed, setSearchPerformed] = useState(false);
    const [loading, setLoading] = useState(false);

    const performSearch = async (searchText) => {
        if (searchText.length <= 0) return;
        try {
            setLoading(true);

            const categoriesRef = collection(firestore, 'categories');
            const categoriesQuerySnapshot = await getDocs(categoriesRef);
            const cardsResults = [];
            const videosResults = [];

            const searchWords = searchText.toLowerCase().split(' '); // Dividir a string de pesquisa em palavras

            for (const categoryDoc of categoriesQuerySnapshot.docs) {
                const cardsRef = collection(categoryDoc.ref, 'cards');
                const cardsQuerySnapshot = await getDocs(cardsRef);

                for (const cardDoc of cardsQuerySnapshot.docs) {
                    const cardData = cardDoc.data();
                    const cardTitle = cardData.title.toLowerCase();
                    const titleWords = cardTitle.split(' ');

                    // Verifica se a consulta tem mais de uma palavra
                    if (searchWords.length > 1) {
                        // Verifica se o título completo corresponde à consulta
                        if (titleWords.join(' ') === searchText.toLowerCase()) {
                            cardsResults.push({ categoryId: categoryDoc.id, cardId: cardDoc.id, title: cardData.title, backgroundImage: { url: cardData?.backgroundImage?.url ? cardData?.backgroundImage?.url : cardData?.img } });
                        }
                    } else {
                        // Verifica se pelo menos uma palavra do título corresponde à consulta
                        const titleContainsSearchWords = titleWords.some((word) => searchWords.includes(word));
                        if (titleContainsSearchWords) {
                            cardsResults.push({ categoryId: categoryDoc.id, cardId: cardDoc.id, title: cardData.title, backgroundImage: { url: cardData?.backgroundImage?.url ? cardData?.backgroundImage?.url : cardData?.img } });
                        }
                    }

                    const videosRef = collection(cardDoc.ref, 'videos');
                    const videosQuerySnapshot = await getDocs(videosRef);

                    videosQuerySnapshot.forEach((videoDoc) => {
                        const videoData = videoDoc.data();
                        const videoTitle = videoData.title.toLowerCase();

                        if (searchWords.length === 1) {
                            // Quando houver apenas uma palavra na consulta, use a lógica existente
                            const videoTitleContainsSearchWords = videoTitle.includes(searchText.toLowerCase());
                            if (videoTitleContainsSearchWords) {
                                videosResults.push({ categoryId: categoryDoc.id, cardId: cardDoc.id, videoId: videoDoc.id, title: videoData.title, backgroundImage: { url: videoData?.backgroundImage?.url || videoData?.image } });
                            }
                        } else {
                            // Quando houver mais de uma palavra na consulta, verifique a correspondência exata
                            const allWordsMatch = searchWords.every((word) => videoTitle.includes(word));
                            if (allWordsMatch) {
                                videosResults.push({ categoryId: categoryDoc.id, cardId: cardDoc.id, videoId: videoDoc.id, title: videoData.title, backgroundImage: { url: videoData?.backgroundImage?.url || videoData?.image } });
                            }
                        }
                    });

                }
            }
            // Ordenar os resultados por título
            cardsResults.sort((a, b) => a.title.localeCompare(b.title));
            videosResults.sort((a, b) => a.title.localeCompare(b.title));

            setSearchResults({ cardsResults, videosResults });
            setSearchPerformed(true);
            setLoading(false);
        } catch (error) {
            console.error('Error performing search:', error);
            setLoading(false);
        }
    };


    const handleClearSearch = () => {
        setSearchText('');
        setSearchResults({ cardsResults: [], videosResults: [] });
        setSearchPerformed(false);
    };

    return (
        <>
            <View style={{ paddingHorizontal: "1.5rem" }}>
                <StandardText textAlign="left" color={THEME.COLORS.TEXT_MAIN}>Buscar</StandardText>
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingVertical: 5,
                        borderWidth: 1,
                        borderColor: '#ccc',
                        borderRadius: 7,
                        marginTop: 10
                    }}
                >
                    <Feather name="search" size={15} color={THEME.COLORS.TITLE_MAIN} style={{ padding: 5 }} />
                    <TextInput
                        placeholder="Busque por módulo, conteúdo..."
                        value={searchText}
                        onChangeText={(text) => {
                            setSearchText(text);
                            if (text.length === 0) {
                                setSearchResults({ cardsResults: [], videosResults: [] });
                            }
                        }}
                        returnKeyType="go"
                        onSubmitEditing={(event) => {
                            const text = event.nativeEvent.text;
                            if (text.length >= 3) {
                                performSearch(text);
                            } else {
                                setSearchResults({ cardsResults: [], videosResults: [] });
                            }
                        }}
                        style={{
                            flex: 1,
                            color: THEME.COLORS.TITLE_MAIN,
                            marginEnd: 5,
                            backgroundColor: 'transparent',
                            borderWidth: 0,
                            outline: 'none',
                        }}
                    />
                    {searchText !== '' && !loading && (
                        <Pressable onPress={handleClearSearch} >
                            <Octicons name="x-circle-fill" size={15} color={THEME.COLORS.TITLE_MAIN} style={{ padding: 5 }} />
                        </Pressable>
                    )}
                </View>
                {searchText.length === 0 &&
                    <FooterText padding="0.5rem 0rem" color={THEME.COLORS.TEXT_MAIN} textAlign="left">Para encontrar o conteúdo que você deseja, por favor, digite o termo na barra de pesquisa acima.</FooterText>
                }
            </View >
            {
                loading ? (
                    <View style={{ height: "100%", alignItems: "center", justifyContent: "center" }} >
                        <ActivityIndicator color={THEME.COLORS.TEXT_MAIN} size={20} />
                    </View >
                ) : searchPerformed &&
                <>
                    {searchResults?.cardsResults?.length > 0 &&

                        <CategoryList
                            searchTitle="MÓDULOS ENCONTRADOS:"
                            cardsResults={searchResults.cardsResults}
                        />
                    }
                    {searchResults?.videosResults?.length > 0 &&
                        <CategoryList
                            searchTitle="CONTEÚDOS ENCONTRADOS:"
                            videosResults={searchResults.videosResults}
                        />
                    }
                    {searchPerformed && searchResults?.cardsResults?.length <= 0 && searchResults?.videosResults?.length <= 0 && searchText.length > 0 &&
                        <FooterText padding="0.5rem 0rem" color={THEME.COLORS.TEXT_MAIN} textAlign="left">Poxa, nenhum resultado foi encontrado! Por favor, tente buscar por outro termo.</FooterText>
                    }
                </>
            }
        </>
    );
};

export default SearchBar;