import React, { useEffect, useState } from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { DrawerContent } from "../components/DrawerContent";
import { Success } from "../pages/SuccessPayment";
import { ClickClass } from "../pages/ClickClass";
import { ClickCard } from "../pages/ClickCard";
import { ClickCourse } from "../pages/ClickCourse";
import { Login } from "../pages/Login";
import { SignUp } from "../pages/SignUp";
import { ClickSearch } from "../pages/ClickSearch";
import { Plans } from "../pages/Plans";
import { Main } from "../pages/Main";
import { TermsofUse } from "../pages/TermsofUse";
import { About } from "../pages/About";
import { EditProfile } from "../pages/EditProfile";
import { Favorites } from "../pages/Favorites";
import { onAuthStateChanged, signInAnonymously} from "firebase/auth";
import { auth } from "../services/firebase";
import THEME from "../config/theme";
import { hasOutsideAboutPage } from "../config/data"
import Constants from "expo-constants";

const Stack = createNativeStackNavigator();
const Drawer = createDrawerNavigator();
const { featureSearchEnabled } = Constants.manifest.extra;

export function AppRoutes() {
  const [login, setLogin] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user && !user.isAnonymous) {
        setLogin(true);
      } else if (!user) {  
        // Nenhum usuário está logado, realizar login anônimo.
       signInAnonymously(auth)
       .then(() => {
       // Login anônimo realizado com sucesso.
       console.log('Usuário logado anonimamente');
       })
       .catch((error) => {
       // Houve um erro ao realizar login anônimo.
       console.error(error);
       });
         } 
    });
  }, []);

  function DrawerRoutes() {
    return (
      <Drawer.Navigator
        drawerContent={(props) => <DrawerContent {...props} />}
        screenOptions={{
          headerShown: false,
          drawerStatusBarAnimation: "fade",
          drawerActiveBackgroundColor: THEME.COLORS.DRAWER_SELECTED,
          drawerActiveTintColor: THEME.COLORS.BACKGROUND_ABOUT,
          drawerInactiveTintColor: THEME.COLORS.DRAWER_SELECTED,
          drawerStyle: {
            backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
          },
          drawerLabelStyle: {
            fontSize: THEME.FONTSIZE.SMALL,
            fontFamily: THEME.FONTFAMILY.REGULAR,
          },
        }}
      >
        <Drawer.Screen name="Aplicativo" component={Main} />
        {login ? (
          <>
            <Drawer.Screen name="Meu Perfil" component={EditProfile} />
            <Drawer.Screen name="Meus Favoritos" component={Favorites} />
          </>
        ) :
          <Drawer.Screen name="Fazer Login" component={Login} />
        }
        <Drawer.Screen name="Termos de Uso">
          {() => <TermsofUse />}
        </Drawer.Screen>
      </Drawer.Navigator>
    );
  }

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      {!hasOutsideAboutPage ? <Stack.Screen name="Inicio" component={About} /> :
        <Stack.Screen name="Conteudo" component={DrawerRoutes} />
      }
      {!hasOutsideAboutPage ? <Stack.Screen name="Conteudo" component={DrawerRoutes} /> :
        undefined
      }
      <Stack.Screen name="Planos" component={Plans} />
      <Stack.Screen name="Login" component={Login} />
      <Stack.Screen name="Cadastro" component={SignUp} />
      <Stack.Screen name="Modulo" component={ClickCard} />
      <Stack.Screen name="Aula" component={ClickClass} />
      <Stack.Screen name="Produto" component={ClickCourse} />
      <Stack.Screen name="TermosdeUso" component={TermsofUse} />
      <Stack.Screen name="Sucesso" component={Success} />
      <Stack.Screen name="MeuPerfil" component={EditProfile} />
      <Stack.Screen name="Favoritos" component={Favorites} />
      {featureSearchEnabled ? <Stack.Screen name="Buscar" component={ClickSearch} /> :
        undefined
      }
    </Stack.Navigator>
  );
}