import React from "react";
import {
  HeaderLeftSide,
  HeaderRightSide,
  Avatar,
  Row,
  ViewLogo,
  Content,
} from "./style";
import { Ionicons, Feather } from '@expo/vector-icons'
import THEME from "../../config/theme";
import { HeaderContainer } from "../../config/theme/globalStyles";
import { DrawerActions } from "@react-navigation/native";
import TouchableText from "../../components/TouchableText";
import { TouchableOpacity, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { aspectRatioLogoAbout, aspectRatioLogoMain } from "../../config/data";
import Button from "../Button";
import { auth } from "../../services/firebase";
import { signOut } from "firebase/auth";
import ViewPortProvider from "../../hooks/ViewPortProvider";
import useViewport from "../../hooks/useViewport";
import LogoAbout from "../../../assets/LogoAbout.png"
import LogoMain from "../../../assets/LogoMain.png"
import { hasOutsideAboutPage } from "../../config/data";
import Constants from "expo-constants";

const Header = ({ about, onPressEditProfile, onPress, user, activePage }) => {
  const navigation = useNavigation();

  const drawerIconColor = activePage && activePage === 'Main' ? THEME.COLORS.ICON_HEADER_MAIN : THEME.COLORS.TEXT_ABOUT;
  const { featureSearchEnabled } = Constants.manifest.extra;

  const handleToggleDrawer = () => {
    navigation.dispatch(DrawerActions.toggleDrawer());
  };

  const logout = () => {
    signOut(auth)
      .then(() => {
        window.location.assign("../About");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const MobileOrDesktopComponent = () => {
    const { width } = useViewport();
    const breakpoint = 1080;
    return width < breakpoint ? (
      <HeaderContainer
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <ViewLogo>
          <Avatar
            resizeMode="contain"
            width={`${aspectRatioLogoAbout * 48}px`}
            source={LogoAbout}
          />
        </ViewLogo>
        <Content>
          {user ? (
            <>
              <View style={{ marginRight: "1rem" }}>
                <Button
                  title={"Acessar conteúdo"}
                  colorbutton={THEME.COLORS.S1_BUTTON_HEADER}
                  onPress={onPress}
                  colortitle={THEME.COLORS.S1_HEADER_CONTENT}
                  fontFamily={THEME.FONTFAMILY.REGULAR}
                  fontSize={THEME.FONTSIZE.SMALL}
                  padding="0.3rem"
                ></Button>
              </View>
              <Button
                title={"Sair"}
                colorbutton="transparent"
                colortitle={THEME.COLORS.S1_HEADER_LOGIN}
                onPress={logout}
                fontFamily={THEME.FONTFAMILY.REGULAR}
                fontSize={THEME.FONTSIZE.SMALL}
                padding="0.3rem"
              ></Button>
            </>
          ) : (
            <>
              <View style={{ marginRight: "1rem" }}>
                <Button
                  title={"Visualizar Conteúdo"}
                  colortitle={THEME.COLORS.S1_HEADER_CONTENT}
                  colorbutton={THEME.COLORS.S1_BUTTON_HEADER}
                  onPress={onPress}
                  fontFamily={THEME.FONTFAMILY.REGULAR}
                  fontSize={THEME.FONTSIZE.SMALL}
                  padding="0.3rem"
                ></Button>
              </View>
              <Button
                title={"Login"}
                colorbutton="transparent"
                colortitle={THEME.COLORS.S1_HEADER_LOGIN}
                onPress={() => navigation.navigate("Login")}
                fontFamily={THEME.FONTFAMILY.REGULAR}
                fontSize={THEME.FONTSIZE.SMALL}
                padding="0.3rem"
              ></Button>
            </>
          )}
        </Content>
      </HeaderContainer>
    ) : (
      <HeaderContainer>
        <HeaderLeftSide>
          <Avatar
            resizeMode="contain"
            width={`${aspectRatioLogoAbout * 48}px`}
            source={LogoAbout}
          />
        </HeaderLeftSide>
        <HeaderRightSide style={{ alignItems: "center" }}>
          {user ? (
            <>
              <View style={{ marginRight: "1rem" }}>
                <Button
                  title={"Acessar conteúdo"}
                  colorbutton={THEME.COLORS.S1_BUTTON_HEADER}
                  colortitle={THEME.COLORS.S1_HEADER_CONTENT}
                  onPress={onPress}
                  fontFamily={THEME.FONTFAMILY.REGULAR}
                  fontSize={THEME.FONTSIZE.SMALL}
                  padding="0.3rem"
                ></Button>
              </View>
              <Button
                title={"Sair"}
                colortitle={THEME.COLORS.S1_HEADER_LOGIN}
                colorbutton="transparent"
                onPress={logout}
                fontFamily={THEME.FONTFAMILY.REGULAR}
                fontSize={THEME.FONTSIZE.SMALL}
                padding="0.3rem"
              ></Button>
            </>
          ) : (
            <>
              <View style={{ marginRight: "1rem" }}>
                <Button
                  title={"Visualizar Conteúdo"}
                  colorbutton={THEME.COLORS.S1_BUTTON_HEADER}
                  colortitle={THEME.COLORS.S1_HEADER_CONTENT}
                  onPress={onPress}
                  fontFamily={THEME.FONTFAMILY.REGULAR}
                  fontSize={THEME.FONTSIZE.SMALL}
                  padding="0.3rem"
                ></Button>
              </View>
              <Button
                title={"Login"}
                colorbutton="transparent"
                colortitle={THEME.COLORS.S1_HEADER_LOGIN}
                onPress={() => navigation.navigate("Login")}
                fontFamily={THEME.FONTFAMILY.REGULAR}
                fontSize={THEME.FONTSIZE.SMALL}
                padding="0.3rem"
              ></Button>
            </>
          )}
        </HeaderRightSide>
      </HeaderContainer>
    );
  };

  return user?.isAdmin ? (
    <HeaderContainer>
      <HeaderLeftSide>
        {onPress ? (
          <Row>
            <Feather
              name="chevron-left"
              color={THEME.COLORS.ICON_HEADER_CLICKCLASS}
              size={THEME.FONTSIZE.BIG}
              onPress={onPress}
            />
            <TouchableText
              onPress={onPress}
              title={"Voltar"}
              color={THEME.COLORS.ICON_HEADER_CLICKCLASS}
            ></TouchableText>
          </Row>
        ) : (
          <Feather
            name="menu"
            color={drawerIconColor}
            size={34}
            onPress={handleToggleDrawer}
          />
        )}
      </HeaderLeftSide>

      <HeaderRightSide>
        {about ? (
          <>
            <TouchableOpacity
              style={{ height: "3rem" }}
              onPress={onPress}
            >
              {aspectRatioLogoMain?.length > 0 &&
                <Avatar
                  resizeMode="contain"
                  source={LogoMain}
                  width={`${aspectRatioLogoMain * 48}px`}
                />
              }
            </TouchableOpacity>
          </>
        ) : (
          <>
            {aspectRatioLogoMain?.length > 0 &&
              <Avatar
                resizeMode="contain"
                source={LogoMain}
                width={`${aspectRatioLogoMain * 48}px`}
              />
            }
          </>
        )}
      </HeaderRightSide>
    </HeaderContainer >
  ) : about ? (
    <ViewPortProvider>
      <MobileOrDesktopComponent />
    </ViewPortProvider>
  ) : (
    <HeaderContainer>
      <HeaderLeftSide>
        {onPress ? (
          <Row>
            <Feather
              name="chevron-left"
              color={THEME.COLORS.ICON_HEADER_CLICKCLASS}
              size={THEME.FONTSIZE.BIG}
              onPress={onPress}
            />
            <TouchableText
              onPress={onPress}
              title={"Voltar"}
              color={THEME.COLORS.ICON_HEADER_CLICKCLASS}
            ></TouchableText>
          </Row>

        ) : onPressEditProfile ? (
          <Row>
            <Feather
              name="chevron-left"
              color={THEME.COLORS.ICON_HEADER}
              size={THEME.FONTSIZE.BIG}
              onPress={onPress}
            />
            <TouchableText
              onPress={onPressEditProfile}
              title={"Voltar"}
              color={THEME.COLORS.ICON_HEADER}
            ></TouchableText>
          </Row>
        ) : (
          <Feather
            name="menu"
            color={drawerIconColor}
            size={34}
            onPress={() => navigation.dispatch(DrawerActions.toggleDrawer())}
          />
        )}
      </HeaderLeftSide>

      <View style={{ height: "3rem", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
        {featureSearchEnabled && (
          <TouchableOpacity
            style={{ marginHorizontal: 5 }}
            onPress={() => {
              navigation.navigate("Buscar");
            }}
          >
            <Ionicons name="ios-search-outline" size={24} color={THEME.COLORS.ICON_HEADER_MAIN} />
          </TouchableOpacity>
        )}
        <TouchableOpacity
          style={{ height: "3rem" }}
          onPress={() => { hasOutsideAboutPage ? {} : navigation.navigate("Inicio") }}
        >
          {aspectRatioLogoMain?.length > 0 &&
            <Avatar
              resizeMode="contain"
              width={`${aspectRatioLogoMain * 48}px`}
              source={LogoMain}
            />
          }
        </TouchableOpacity>
      </View>
    </HeaderContainer>
  );
};

export default Header;