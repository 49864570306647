import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import THEME from '../../../config/theme';
import Button from '../../../components/Button';
import { View } from 'react-native';
import { Title, SmallText, SubTitle } from '../../../config/theme/globalStyles';
import { AlertBox } from "../../../components/AlertBox";
import { Provider } from "react-native-paper";

const Table = ({ tableData, user }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [visibleAlert, setVisibleAlert] = useState(false);
    const [title, setTitle] = useState(null);
    const [message, setMessege] = useState(null);

    const totalUsers = tableData.length;

    const showAlert = (title, message) => {
        setVisibleAlert(true);
        setTitle(title);
        setMessege(message);
    };

    const hideAlert = (status) => {
        setVisibleAlert(status);
    };

    const columns = [
        {
            name: 'Email',
            selector: row => row.Email,
            sortable: true,
        },
        {
            name: 'Data de Cadastro',
            selector: row => {
                // Formate a data para uma string legível
                const createdAtDate = row?.created_at?.toDate(); // convertendo o timestamp para objeto Date
                if (createdAtDate) {
                    const options = {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: false, // Para exibir em formato de 24 horas
                        timeZone: 'America/Sao_Paulo' // Configurando o fuso horário para Brasília
                    };
                    return createdAtDate.toLocaleString('pt-BR', options);
                }
                return '';
            },
            sortable: true,
        },
        {
            name: 'Forma de Cadastro',
            selector: row => {
                if (row?.isSignUpFromApp) return "Aplicativo Mobile"
                if (row?.isSignUpFromAdminPanel) return "Manualmente"
            },
            sortable: true,
        },
    ];

    const customStyles = {
        rows: {
            style: {
                color: "#000000",
                fontFamily: THEME.FONTFAMILY.LIGHT,
                fontSize: THEME.FONTSIZE.EXTRASMALL
            },
        },
        headCells: {
            style: {
                color: "#000000",
                fontFamily: THEME.FONTFAMILY.MEDIUM,
                fontSize: THEME.FONTSIZE.EXTRASMALL
            },
        },
        cells: {
            style: {
            },
        },
    };

    const handleDownloadCSV = () => {
        // Crie um array de objetos contendo apenas os dados das linhas selecionadas
        const selectedData = selectedRows.map(row => {
            // Retorne um objeto com as propriedades desejadas, por exemplo:
            return {
                Nome_Completo: row.Nome_Completo,
                DataDeCadastro: row.created_at,
            }
        });

        // Crie o conteúdo CSV a partir dos objetos selecionados
        const csvContent = "data:text/csv;charset=utf-8," + selectedData
            .map(row => Object.values(row).join(','))
            .join('\n');

        // Crie um link de download e clique nele para iniciar o download
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'selected_data.csv');
        document.body.appendChild(link);
        link.click();
    };

    return (
        <Provider>
            <View style={{ flexDirection: "row", padding: "1rem", alignItems: "center", justifyContent: "space-between" }}>
                <Title textAlign="left">Usuários cadastrados</Title>
                <Button onPress={() => handleDownloadCSV()} title={"Baixar CSV"} fontFamily={THEME.FONTFAMILY.LIGHT} fontSize={THEME.FONTSIZE.EXTRASMALL}></Button>
            </View>
            <View style={{ flexDirection: "row", padding: "1rem", alignItems: "center", justifyContent: "space-between" }}>
                <SmallText textAlign="left" color="#2e2e2e">Aqui você verá uma lista de todos os usuários que se cadastraram no seu aplicativo pelo formulário de cadastro do app Mobile (publicado na Apple Store e Google Play) ou que administradores deram acesso manualmente. Ou seja, eles não se cadastraram no aplicativo através do formulário de checkout da Guru Digital. Importante você saber que eles podem só ter se cadastrado ou podem já ter efetuado compras. Para gestão das suas vendas e envio de e-mails a essas pessoas, baixe essa planilha no botão "baixar csv" e importe ela na sua plataforma de e-mail marketing.</SmallText>
            </View>
            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-evenly", padding: "1rem" }}>
                <View style={{
                    backgroundColor: "#f0f0f0",
                    borderRadius: 10,
                    padding: 10,
                }}>
                    <SubTitle>Total de usuários</SubTitle>
                    <Title>{totalUsers}</Title>
                </View>
            </View>
            <DataTable
                columns={columns}
                data={tableData}
                highlightOnHover
                pointerOnHover
                selectableRows
                selectableRowsHighlight
                selectableRowsVisibleOnly
                customStyles={customStyles}
                onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
            />
            {visibleAlert && (
                <AlertBox
                    title={title}
                    message={message}
                    visible={visibleAlert}
                    onClose={hideAlert}
                ></AlertBox>
            )}
        </Provider>
    );
}

export default Table;