import React, { useEffect, useState } from 'react';
import { FlatList, View, Linking } from 'react-native';
import { Poster, Gradient, Content, Hero, Container, ViewButton } from './style';
import Header from '../../components/Header';
import CategoryList from '../../components/CategoryList';
import { getDocs, collection, doc, getDoc, orderBy, query, setDoc } from "firebase/firestore";
import { firestore } from "../../services/firebase";
import { firestoreAutoId } from "../../utils"
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import THEME from '../../config/theme';
import { Title, SubTitle } from '../../config/theme/globalStyles';
import { titleMain, subtitleMain } from '../../config/data';
import { SafeAreaView } from "react-native";
import FotoMain from "../../../assets/FotoMain.jpg";
import Button from "../../components/Button";
import { Provider } from "react-native-paper";
import { CommonActions } from '@react-navigation/native';
import { ActivityIndicator } from "react-native-paper";
import { FooterText } from '../../config/theme/globalStyles';
import { Icon } from "react-native-elements";

const auth = getAuth();

export function Main({ navigation }) {
  const [allCategories, setAllCategories] = useState([]);
  const [allCategoriesAdmin, setAllCategoriesAdmin] = useState([]);
  const [user, setUser] = useState();
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const showModal = () => setVisible(true);

  const getUserInformation = async (userId) => {
    const docRef = doc(firestore, "users", userId);
    const docSnap = await getDoc(docRef);
    setUser(docSnap.data());
    setIsLoading(false);
  };

  const categories = [
    {
      title: "NOME CATEGORIA",
      order: allCategoriesAdmin.length + 1,
      archived: true,
      cards: [
        {
          title: "Nome Card 1",
          img: "",
          description: "",
          order: 1,
          archived: true,
        },
      ],
    },
  ];

  useEffect(() => {
    const findAllCategories = async () => {
      const response = await getDocs(
        query(collection(firestore, "categories"), orderBy("order", "asc"))
      );
      let categories = [];
      let categoriesAdmin = [];
      response.forEach((doc) => {
        if (doc.data().archived == false) {
          categories.push({ id: doc.id, ...doc.data() });
        }
        categoriesAdmin.push({ id: doc.id, ...doc.data() });
      });
      setAllCategories(categories);
      setAllCategoriesAdmin(categoriesAdmin);
    };
    onAuthStateChanged(auth, (user) => {
      if (user && !user.isAnonymous) {
        getUserInformation(user.uid);
      } else if(!user) {  
     // Nenhum usuário está logado, realizar login anônimo.
    signInAnonymously(auth)
    .then(() => {
      setIsLoading(false);
    // Login anônimo realizado com sucesso.
    })
    .catch((error) => {
    // Houve um erro ao realizar login anônimo.
    console.error(error);
    });
      } else {
        setIsLoading(false);
      }
    });
    findAllCategories();
  }, []);


  const firestoreAddCategory = () => {
    let firestoreId = firestoreAutoId();
    const categoriesCollectionRef = doc(firestore, "categories", firestoreId);
    const createCategories = async () => {
      await setDoc(
        categoriesCollectionRef,
        {
          title: categories[0].title,
          order: categories[0].order,
          archived: categories[0].archived,
        },
        { merge: true }
      );
    };
    createCategories();
    const createCards = async () => {
      const cardsRef = doc(
        firestore,
        "categories",
        firestoreId,
        "cards",
        firestoreAutoId()
      );
      await setDoc(
        cardsRef,
        {
          title: categories[0].cards[0].title,
          img: categories[0].cards[0].img,
          description: categories[0].cards[0].description,
          order: categories[0].cards[0].order,
          archived: categories[0].cards[0].archived
        },
        { merge: true }
      );
    };
    createCards();
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{ name: 'Painel' }],
      }),
    );
  };

  const footer = (
    <>
      <View
        style={{ height: 0.9, width: '100%', backgroundColor: THEME.COLORS.TEXT_MAIN, marginTop: "3.5rem" }}
      />
      <View
        style={{ width: '100%', backgroundColor: THEME.COLORS.BACKGROUND_MAIN, flexDirection: "row", alignItems: "center", justifyContent: "center", paddingVertical: "1rem" }}
      >
        <FooterText padding="0rem 0.2rem" color={THEME.COLORS.TEXT_MAIN}>Desenvolvido com</FooterText>
        <Icon
          type="material-community"
          name="heart"
          color={THEME.COLORS.TEXT_MAIN}
          size="12px"
        />
        <FooterText padding="0rem 0.2rem" color={THEME.COLORS.TEXT_MAIN}>pela</FooterText>
        <FooterText fontFamily={THEME.FONTFAMILY.BOLD} color={THEME.COLORS.TEXT_MAIN} padding="0rem 0.3rem" onPress={() => Linking.openURL("https://www.instagram.com/blix.tecnologia/")}>BLIX</FooterText>
      </View>
    </>
  );

  return isLoading ? (
    <ActivityIndicator
      style={{
        flex: 1,
        backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
        justifyContent: "center",
      }}
      color={THEME.COLORS.PRIMARY_800}
    />
  ) : user?.isAdmin ? (
    <Provider>
      <SafeAreaView style={{ flexGrow: 1, backgroundColor: THEME.COLORS.BACKGROUND_MAIN }}>
        <Container background={THEME.COLORS.BACKGROUND_MAIN}>
          <Poster source={FotoMain}>
            <Gradient
              locations={[0, 0.2, 0.7, 1]}
              colors={THEME.COLORS.GRADIENT_MAIN}
            >
              <Header navigation={navigation} user={user} activePage={"Main"} />
              <Hero>
                <Title
                  color={THEME.COLORS.TITLE_OVERPHOTO_MAIN}
                  textAlign="left"
                  margin="0.5rem 0rem"
                >
                  {titleMain}
                </Title>
                <SubTitle
                  color={THEME.COLORS.TITLE_OVERPHOTO_MAIN}
                  textAlign="left"
                >
                  {subtitleMain}
                </SubTitle>
              </Hero>
            </Gradient>
          </Poster>
          <Content>
            <FlatList
              data={allCategoriesAdmin}
              showsHorizontalScrollIndicator={false}
              keyExtractor={(item) => item.order}
              renderItem={({ item }) => (
                <CategoryList
                  category={item}
                  modalFunction={showModal}
                  navigation={navigation}
                  user={user}
                />
              )}
            />
          </Content>
          <ViewButton>
            <Button
              title={"+ NOVA CATEGORIA"}
              onPress={firestoreAddCategory}
            ></Button>
          </ViewButton>
          {footer}
        </Container>
      </SafeAreaView>
    </Provider>
  ) : (
    <SafeAreaView style={{ flexGrow: 1, backgroundColor: THEME.COLORS.BACKGROUND_MAIN }}>
      <Container background={THEME.COLORS.BACKGROUND_MAIN}>
        <Poster source={FotoMain}>
          <Gradient
            locations={[0, 0.2, 0.7, 1]}
            colors={THEME.COLORS.GRADIENT_MAIN}>
            <Header navigation={navigation} user={user} activePage={"Main"} />
            <Hero>
              <Title color={THEME.COLORS.TITLE_OVERPHOTO_MAIN} textAlign="left" margin="0.5rem 0rem">{titleMain}</Title>
              <SubTitle color={THEME.COLORS.TITLE_OVERPHOTO_MAIN} textAlign="left">{subtitleMain}</SubTitle>
            </Hero>
          </Gradient>
        </Poster>
        <Content>
          <FlatList
            data={allCategories}
            showsHorizontalScrollIndicator={false}
            renderItem={({ item }) => <CategoryList category={item} user={user} />}
          />
        </Content>
        {footer}
      </Container>
    </SafeAreaView>
  );
}