import { useState, useEffect, useCallback } from 'react';
import {
    View,
    SafeAreaView,
    FlatList,
    ActivityIndicator,
    TouchableOpacity,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { firestore } from '../../services/firebase';
import {
    collection,
    query,
    where,
    onSnapshot,
    getDoc,
    doc,
    setDoc,
} from 'firebase/firestore';
import Header from '../../components/Header';
import { SmallText, SubTitle } from '../../config/theme/globalStyles';
import THEME from '../../config/theme';
import PlayList from '../../components/PlayList';

const auth = getAuth();

export function Favorites() {
    const [favorites, setFavorites] = useState([]);
    const [videos, setVideos] = useState([]);
    const [load, setLoad] = useState(true);
    const [user, setUser] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const navigation = useNavigation();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user && !user.isAnonymous) {
                setUser(user);
            } else if(!user) {  
                // Nenhum usuário está logado, realizar login anônimo.
               signInAnonymously(auth)
               .then(() => {
                setIsLoading(false);
               // Login anônimo realizado com sucesso.
               })
               .catch((error) => {
               // Houve um erro ao realizar login anônimo.
               console.error(error);
               });
              }
            setIsLoading(false);
        })

        return () => unsubscribe()
    }, []);

    const fetchFavorites = useCallback(async () => {
        setLoad(true);
        const q = query(
            collection(firestore, 'users', user?.uid, 'contents'),
            where('isFavorite', '==', true)
        );
        try {
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const videoIds = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setFavorites(videoIds);
            });

            return () => unsubscribe();
        } catch (error) {
            console.log('Error fetching favorites:', error);
        }
    }, [user?.uid]);

    const fetchVideos = useCallback(async (favoriteList) => {
        const videos = [];
        for (const favoriteVideo of favoriteList) {
            const docRef = doc(
                firestore,
                'categories',
                favoriteVideo.categoryId,
                'cards',
                favoriteVideo.cardId,
                'videos',
                favoriteVideo.id
            );
            const videoData = await getDoc(docRef);

            if (videoData.exists()) { // Check if the document exists before accessing its data
                videos.push({
                    id: favoriteVideo.id,
                    ...videoData.data(),
                    cardId: favoriteVideo.cardId,
                    categoryId: favoriteVideo.categoryId,
                });
            } else {
                console.log("Video document not found:", favoriteVideo.id);
            }
        }
        setVideos(videos);
        setLoad(false);
        return videos;
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (!isLoading) {
                await fetchFavorites();
            }
        };
        fetchData();
    }, [fetchFavorites, isLoading]);

    useEffect(() => {
        const fetchVideosData = async () => {
            if (favorites.length > 0) {
                try {
                    await fetchVideos(favorites);
                    setLoad(false);
                } catch (error) {
                    // Handle the error properly
                    console.log("Error fetching videos:", error);
                }
            }
            setLoad(false);
        };
        fetchVideosData();
    }, [favorites, fetchVideos]);

    const handleSelectedVideoChange = (item) => {
        navigation.navigate("Aula", {
            cardId: item?.cardId,
            categoryId: item?.categoryId,
            selectedVideo: item?.id,
        })
    };

    const handleRemoveLike = async (videoId, cardId, categoryId) => {
        try {
            const contentsDocRef = doc(
                firestore,
                "users",
                user?.uid,
                "contents",
                videoId
            );
            await setDoc(contentsDocRef, {
                cardId,
                categoryId,
                isFavorite: false
            }, { merge: true });
        } catch (error) {
            console.error("Error adding document isFavorite: ", error);
        }
    };


    const ItemSeparatorView = () => {
        return (
            <View
                style={{ height: 0.1, backgroundColor: `${THEME.COLORS.TEXT_MAIN}40` }}
            />
        );
    };

    return (
        <SafeAreaView
            style={{
                flexGrow: 1,
                backgroundColor: THEME.COLORS.BACKGROUND_MAIN,
                alignItems: "center",
            }}
        >
            <Header
                onPress={() => {
                    navigation.navigate("Conteudo", { screen: "Aplicativo" });
                }}
                user={user?.uid}
            />
            <View style={{ marginBottom: 30, paddingHorizontal: 10 }}>
                <SubTitle color={THEME.COLORS.TEXT_MAIN} padding="0.5rem 0rem">Meus favoritos</SubTitle>
                <SmallText color={THEME.COLORS.TEXT_MAIN}>Todos os conteúdos que você adicionar como favorito irão aparecer nesta seção "Meus favoritos"</SmallText>
            </View>
            {load ? (
                <ActivityIndicator size="large" />
            ) : (
                <View style={{
                    alignItems: "center", width: "100%", paddingHorizontal: "1rem"
                }}>
                    <FlatList
                        data={videos}
                        ItemSeparatorComponent={ItemSeparatorView}
                        renderItem={({ item, index }) => (
                            <TouchableOpacity
                                style={{ marginVertical: "0.8rem" }}
                                key={item.key}
                                onPress={() => handleSelectedVideoChange(item)}
                            >
                                <PlayList {...item} user={user} favorites={true} handleRemoveLike={handleRemoveLike} ></PlayList>
                            </TouchableOpacity>
                        )}
                    />
                </View>
            )}
        </SafeAreaView>
    );
};