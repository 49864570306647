import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { firestore } from "../../services/firebase/index";
import THEME from "../../config/theme";
import { ActivityIndicator } from "react-native-paper";
import { Container } from "./style";
import { useNavigation } from "@react-navigation/native";
import Table from "../../components/DataTable";
import {
    getDocs,
    collection,
    orderBy,
    query,
    getDoc,
    doc
} from "firebase/firestore";

const auth = getAuth();

export function Users() {
    const navigation = useNavigation();

    const [allUsers, setAllUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(true);

    const getUserAdmin = async (userId) => {
        const docRef = doc(firestore, "users", userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setUser(docSnap.data())
        }
    };

    const fetchContentsForUsers = async (users) => {
        const promises = users.map(async (user) => {
            const contentsSnapshot = await getDocs(
                collection(firestore, "users", user.id, "contents")
            );

            const watchedContents = contentsSnapshot.docs
                .filter((contentDoc) => contentDoc.data().isWatched)
                .map((contentDoc) => ({
                    categoryId: contentDoc.data().categoryId,
                    cardId: contentDoc.data().cardId,
                    videoId: contentDoc.id,
                    markedAsWatchedAt: contentDoc.data().markedAsWatchedAt
                }));

            return { ...user, watchedContents };
        });

        return Promise.all(promises);
    };

    const fetchVideoTitles = async (usersWithContents) => {
        const usersWithVideoTitles = await Promise.all(
            usersWithContents.map(async (user) => {
                const { watchedContents } = user;
                const userWithVideoTitles = { ...user, watchedVideos: [] };

                for (const content of watchedContents) {
                    const videosCollectionPath = `categories/${content.categoryId}/cards/${content.cardId}/videos`;
                    const videosCollectionRef = collection(firestore, videosCollectionPath);
                    const videosSnapshot = await getDocs(videosCollectionRef);

                    videosSnapshot.forEach((videoDoc) => {
                        const videoData = videoDoc.data();
                        if (videoDoc.id === content.videoId) {
                            userWithVideoTitles.watchedVideos.push(videoData.title);
                        }
                    });
                }

                return userWithVideoTitles;
            })
        );

        return usersWithVideoTitles;
    };


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (authUser) => {
            if (authUser) {
                const fetchUsers = async () => {
                    try {
                        const response = await getDocs(
                            query(collection(firestore, "users"), orderBy("Nome_Completo", "asc"))
                        );
                        const users = response.docs.map((doc) => ({
                            id: doc.id,
                            ...doc.data(),
                        }));

                        const usersWithContents = await fetchContentsForUsers(users);
                        const usersWithVideoTitles = await fetchVideoTitles(usersWithContents);
                        setAllUsers(usersWithVideoTitles);
                        getUserAdmin(authUser.uid);
                    } catch (error) {
                        console.error("Error fetching users:", error);
                    } finally {
                        setLoading(false);
                    }
                };

                fetchUsers();
            } else {
                setLoading(false);
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);


    return loading ? (
        <ActivityIndicator
            style={{
                flex: 1,
                backgroundColor: "#FFFFFF",
                justifyContent: "center",
            }}
            color={THEME.COLORS.PRIMARY_900}
            size="large"
        />
    ) : (
        <Container>
            <Header navigation={navigation} user={user} />
            <Table tableData={allUsers} user={user} />
        </Container>
    );
}