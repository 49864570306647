import React from "react";
import { A } from "@expo/html-elements";
import { ImageBackground, TouchableOpacity, View } from "react-native";
import VideoPlayer from "../../../components/VideoPlayer";
import THEME from "../../../config/theme";
import { ImageBlocked } from "../style";
import { borderRadiusButtons } from "../../../config/data";

const ViewVideo = (props) => {

    const { video, user, card } = props;
    const { plans, coursesCard, img, backgroundImage, productIds } = card || {};
    const { meetingNumber, meetingPassword, title } = video || {};
    const { plan: userPlan, courses: userCourses, isAdmin } = user || {};

    const image = backgroundImage?.url || img;
    const meetingUrl = "https://zoom.us/j/" + meetingNumber + "?pwd=" + meetingPassword;
    const src = video?.videoInfo?.url || video?.pandaVideoMetadata?.video_player || video?.link;

    const toDate = (seconds) => {
        const date = new Date(1970, 0, 1);
        date.setSeconds(seconds);
        return date;
    };

    const content = (
        <>
            {
                (
                    productIds &&
                    productIds?.length > 0 &&
                    productIds?.filter(cardProductId => {
                        const matchingUserProducts = user?.productIds?.filter(userProduct => userProduct.productId === cardProductId);
                        if (!matchingUserProducts || matchingUserProducts.length === 0) {
                            return false; // O usuário não possui este produto, então não tem acesso
                        }
                        // Se não houver data de expiração, ou se alguma data de expiração for maior que a data atual, o usuário tem acesso
                        return matchingUserProducts.some(userProduct => !userProduct.expirationDate || toDate(userProduct.expirationDate.seconds) > new Date());
                    }).length > 0
                )
                    ||
                    (plans &&
                        plans.length > 0 &&
                        plans.filter((plan) => (plan.priceId || plan.productId) == userPlan).length > 0) ||
                    (userCourses &&
                        userCourses.length > 0 &&
                        userCourses.filter((course) => coursesCard?.some((itemToBeRemoved) =>
                            (itemToBeRemoved.priceId || itemToBeRemoved.productId) === (course.priceId || course.productId)) && toDate(course.dueDate.seconds) > new Date()
                        ).length > 0) ||
                    (plans == null && coursesCard == null && productIds == null) || isAdmin === true ? (
                    meetingNumber ? (
                        <ImageBackground
                            source={{ uri: image }}
                            style={{
                                resizeMode: "cover",
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <ImageBlocked>
                                <TouchableOpacity
                                    style={{
                                        backgroundColor: THEME.COLORS.PRIMARY_900,
                                        borderRadius: borderRadiusButtons,
                                        padding: "0.7rem",
                                        margin: "0.8rem",
                                    }}
                                >
                                    <A
                                        href={meetingUrl}
                                        style={{
                                            fontSize: THEME.FONTSIZE.SMALL,
                                            color: THEME.COLORS.TEXT_BUTTON,
                                            fontFamily: THEME.FONTFAMILY.BOLD,
                                            textAlign: "center",
                                        }}
                                    >{`Acessar ao vivo\n${title}`}</A>
                                </TouchableOpacity>
                            </ImageBlocked>
                        </ImageBackground>
                    ) : src ? (
                        <VideoPlayer video={video} />
                    ) : (
                        <ImageBackground
                            source={{ uri: video?.backgroundImage?.url || video?.image || image }}
                            style={{
                                resizeMode: "cover",
                                width: "100%",
                                height: "100%",
                            }}
                        >
                        </ImageBackground>
                    )
                ) : (
                    <></>
                )}
        </>
    );

    return (
        <View
            style={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
                overflow: "hidden",
            }}
        >
            {content}
        </View>
    );
};

export default ViewVideo;