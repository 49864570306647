import React, { useState, useEffect } from "react";
import { View, SafeAreaView } from "react-native";
import ViewPortProvider from "../../hooks/ViewPortProvider";
import useViewport from "../../hooks/useViewport";
import THEME from "../../config/theme";
import { Container } from "../../config/theme/globalStyles";
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import {
  doc,
  addDoc,
  updateDoc,
  getDoc,
  deleteDoc,
  getDocs,
  orderBy,
  query,
  collection,
  onSnapshot,
  where
} from "firebase/firestore";
import { firestore } from "../../services/firebase";
import Header from "../../components/Header";
import { CommonActions } from "@react-navigation/native";
import { ImageUpload } from "../../components/ImagePicker";
import { Provider } from "react-native-paper";
import { EditVideo } from "../../components/EditVideo";
import { AlertBox } from "../../components/AlertBox";
import { useNavigation } from "@react-navigation/native";
import EditCardInformationModal from "./EditCardInformation";
import { firestoreAutoId } from "../../utils";
import { ActivityIndicator } from "react-native-paper";
import { Modal } from "react-native";
import ViewVideo from "./ViewVideo";
import ViewFlatList from "./ViewFlatList";
import FlatListView from "./FlatListView";
import ViewImage from "./ViewImage";
import EditOrder from "./EditOrder";
import { ScrollView } from "react-native";
import Constants from "expo-constants";

const auth = getAuth();

const { guruUserToken } = Constants.manifest.extra;

export function ClickCard({ route }) {
  const { cardId, categoryId, firstPlanPurchase } = route.params;
  const navigation = useNavigation();

  const [selectedId, setSelectedId] = useState(null);
  const [card, setCard] = useState();
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedVideoId, setSelectedVideoId] = useState();
  const [user, setUser] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [showEditCardModal, setShowEditCardModal] = useState(false);
  const [showEditOrderModal, setShowEditOrderModal] = useState(false);
  const [showDeleteCardModal, setShowDeleteCardModal] = useState(false);
  const [showEditVideoModal, setShowEditVideoModal] = useState(false);
  const [showVideoBackgroundUploadModal, setShowVideoBackgroundUploadModal] =
    useState(false);
  const [showCardBackgroundUploadModal, setShowCardBackgroundUploadModal] =
    useState(false);

  const [showDeleteVideoModal, setShowDeleteVideoModal] = useState(false);

  const [showOkAlert, setShowOkAlert] = useState(false);
  const [okAlertMessage, setOkAlertMessage] = useState("");

  const [plans, setPlans] = useState([]);
  const [courses, setCourses] = useState([]);
  const [products, setProducts] = useState([]);
  const [videoUnsubscribes, setVideoUnsubscribes] = useState([]);

  const { planType } = user || {};
  const { img, backgroundImage } = card || {};
  const cardBackgroundImageUrl = backgroundImage?.url || img;


  const getUser = async (user) => {
    const docRef = doc(firestore, "users", user);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) return;
    setUser(() => ({ id: docSnap.id, ...docSnap.data() }));
  };

  const updateVideoOrder = async (updatedVideos) => {
    try {
      setIsSaving(true);
      for (const index in updatedVideos) {
        const newDocId = updatedVideos[index].docId;
        const videoRef = doc(
          firestore,
          "categories",
          categoryId,
          "cards",
          cardId,
          "videos",
          newDocId
        );
        // Needed to reverse logic due to previous implementation that top is the highest number.
        await updateDoc(videoRef, { order: +index });
      }
    } catch (error) {
      console.error("Failed to update video order: ", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleSelectedVideoChange = (video) => {
    setSelectedVideo(() => video),
      navigation.navigate("Aula", {
        cardId: cardId,
        categoryId: categoryId,
        firstPlanPurchase: firstPlanPurchase,
        selectedVideo: video.docId,
      })
  };

  const handleOnDeleteVideoClick = (video) => {
    setSelectedVideoId(video.docId);
    setShowDeleteVideoModal(true);
  };

  const handleOnDeleteVideoConfirm = async () => {
    await deleteDoc(
      doc(
        firestore,
        "categories",
        categoryId,
        "cards",
        cardId,
        "videos",
        selectedVideoId
      )
    );
  };

  const handleOnDeleteCardConfirm = async () => {
    await deleteDoc(doc(firestore, "categories", categoryId, "cards", cardId));
    // Rever sistema de roteamento
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{ name: "Painel" }],
      })
    );
  };


  const fetchProducts = async (productIds) => {
    const productsCollection = collection(firestore, 'products');
    const q = query(productsCollection, where("marketplace", "==", "Guru Digital"));
    const querySnapshot = await getDocs(q);
    const products = [];

    querySnapshot.forEach(doc => {
      const productData = doc.data();
      const productId = productData?.productId;
      const isSelected = productIds?.includes(productId);
      products.push({
        ...productData,
        selected: isSelected
      });
    });

    setProducts(products)
  }

  const fetchData = async () => {
    const docRef = doc(firestore, "categories", categoryId, "cards", cardId);
    const docSnap = await getDoc(docRef);

    const data = { docId: docSnap.id, ...docSnap.data() };

    fetchProducts(data?.productIds)

    let plans = [];
    if (data.plans) {
      for (let i = 0; i < data.plans.length; i++) {
        plans.push(data.plans[i]);
      }
    }

    let plansCard = [];
    if (data.plans) {
      for (let i = 0; i < data.plans.length; i++) {
        plansCard.push(data.plans[i]);
      }
    }

    const getPlans = async (plans) => {
      const plansSnap = await getDocs(
        query(collection(firestore, `plans`), orderBy("order", "asc"))
      );

      let plansCollection = [];

      plansSnap.forEach((doc) => {
        const plan = doc.data();
        if (plan.isPlanFrequency == false || plan.isPlanFrequency == null) {
          if (plan.monthlyPriceId) {
            plans.push({
              id: doc.id,
              title: `${plan.title} Mensal`,
              selected: false,
              productId: plan.productIds.monthly,
            }),
              plansCollection.push({
                id: doc.id,
                title: `${plan.title} Mensal`,
                selected: false,
                productId: plan.productIds.monthly,
              });
          }
          if (plan.quarterlyPriceId || plan.quarterlyAtSightPriceId) {
            plans.push({
              id: doc.id,
              title: `${plan.title} Trimestral`,
              selected: false,
              productId: plan.productIds.quarterly,
            }),
              plansCollection.push({
                id: doc.id,
                title: `${plan.title} Trimestral`,
                selected: false,
                productId: plan.productIds.quarterly,
              });
          }
          if (plan.semiannualPriceId || plan.semiannualAtSightPriceId) {
            plans.push({
              id: doc.id,
              title: `${plan.title} Semestral`,
              selected: false,
              productId: plan.productIds.semiannual,
            }),
              plansCollection.push({
                id: doc.id,
                title: `${plan.title} Semestral`,
                selected: false,
                productId: plan.productIds.semiannual,
              });
          }
          if (plan.yearlyPriceId || plan.yearlyAtSightPriceId) {
            plans.push({
              id: doc.id,
              title: `${plan.title} Anual`,
              selected: false,
              productId: plan.productIds.yearly,
            }),
              plansCollection.push({
                id: doc.id,
                title: `${plan.title} Anual`,
                selected: false,
                productId: plan.productIds.yearly,
              });
          }
        }
      });
      const duplicateIds = plans
        .map((v) => v.productId)
        .filter((v, i, vIds) => vIds.indexOf(v) !== i);
      plans.filter((obj) =>
        duplicateIds.includes(obj.productId)
      );
      const duplicates2 = plans.filter((obj) => obj.selected === true);
      function getDifference(array1, array2) {
        return array1.filter((object1) => {
          return !array2.some((object2) => {
            return object1.productId === object2.productId;
          });
        });
      }

      let difference = getDifference(plansCollection, plansCard);

      for (let i = 0; i < duplicates2.length; i++) {
        difference.push(duplicates2[i]);
      }

      setPlans(difference);
    };

    let courses = [];
    if (docSnap.data().coursesCard) {
      for (let i = 0; i < docSnap.data().coursesCard.length; i++) {
        courses.push(docSnap.data().coursesCard[i]);
      }
    }

    let coursesCard = [];
    if (docSnap.data().coursesCard) {
      for (let i = 0; i < docSnap.data().coursesCard.length; i++) {
        coursesCard.push(docSnap.data().coursesCard[i]);
      }
    }
    const getCourses = async (courses) => {
      const response = await getDocs(collection(firestore, `courses`));

      let coursesCollection = [];
      response.forEach((doc) => {
        courses.push({
          id: doc.id,
          title: doc.data().title,
          productId: doc.data().productId,
        });
        coursesCollection.push({
          id: doc.id,
          title: doc.data().title,
          productId: doc.data().productId,
        });
      });

      const duplicateIds = courses
        .map((v) => v.productId)
        .filter((v, i, vIds) => vIds.indexOf(v) !== i);
      courses.filter((obj) =>
        duplicateIds.includes(obj.productId)
      );
      const duplicates2 = courses.filter((obj) => obj.selected === true);

      function getDifference(array1, array2) {
        return array1.filter((object1) => {
          return !array2.some((object2) => {
            return object1.productId === object2.productId;
          });
        });
      }

      let difference = getDifference(coursesCollection, coursesCard);

      for (let i = 0; i < duplicates2.length; i++) {
        difference.push(duplicates2[i]);
      }

      setCourses(difference);
    };
    const getCoursesPromise = getCourses(courses);
    const getPlansPromise = getPlans(plans);
    await Promise.all([getCoursesPromise, getPlansPromise]);

    setIsLoading(false);
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user && !user.isAnonymous) {
        getUser(user.uid).then(() => {
          fetchData();
        })
      }
      else if(!user) {  
        // Nenhum usuário está logado, realizar login anônimo.
        signInAnonymously(auth)
          .then(() => {
            setIsLoading(false);
            // Login anônimo realizado com sucesso.
          })
          .catch((error) => {
            // Houve um erro ao realizar login anônimo.
            console.error(error);
          });
      } else {
        fetchData();
      }
    });
  }, []);

  useEffect(() => {
    const run = async () => {
      const videosRef = collection(
        firestore,
        "categories",
        categoryId,
        "cards",
        cardId,
        "videos"
      );
      const videosSnap = await getDocs(videosRef);
      if (!videosSnap.docs.length) return;
      videosSnap.docs.map((doc) => subscribeVideo(doc.ref));
    };
    run();
    return () => videoUnsubscribes.forEach((unsubscribe) => unsubscribe());
  }, []);

  // Load card data and receive updates.
  useEffect(() => {
    let unsubscribe = null;

    const run = async () => {
      const docRef = doc(firestore, "categories", categoryId, "cards", cardId);
      unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (!docSnap.exists()) return;
        const card = { id: docSnap.id, ...docSnap.data() };
        setCard(card);
      });
    };
    run();

    return () => unsubscribe && unsubscribe();
  }, [categoryId, cardId]);

  const subscribeVideo = (videoRef) => {
    const unsubscribe = onSnapshot(videoRef, (docSnap) => {
      setVideos((prevState) => {
        const filteredVideos = prevState.filter(
          (video) => video.docId !== docSnap.id,
        );

        // Document has been deleted
        if (!docSnap.exists()) {
          return filteredVideos;
        }

        const data = docSnap.data();
        const descriptionVideo = docSnap.data().description ? docSnap.data().description : [""]
        const doc = { docId: docSnap.id, ...data, description: descriptionVideo };

        return [...filteredVideos, doc].sort((a, b) => a.order - b.order);
      });
    });
    setVideoUnsubscribes((prevState) => [...prevState, unsubscribe]);
  };

  const addVideo = async (type = "content") => {
    try {
      const numOfVideosWithSameType =
        videos.filter((video) => video.type === type).length + 1;

      const title =
        (type === "content" &&
          `Nome do Vídeo ou Áudio #${numOfVideosWithSameType}`) ||
        (type === "live" &&
          `Nome da Aula ao Vivo #${numOfVideosWithSameType}`) ||
        "";

      const newVideo = {
        title,
        order: videos[0] ? videos[0]?.order - 1 : videos.length,
        type,
        videoId: `${firestoreAutoId()}${type}`, // This will be deprecated soon
      };

      const videosRef = collection(
        firestore,
        "categories",
        categoryId,
        "cards",
        cardId,
        "videos"
      );

      const docRef = await addDoc(videosRef, newVideo);

      subscribeVideo(docRef);

      const alertMessage =
        (type === "content" && `Novo vídeo/áudio adicionado com sucesso!`) ||
        (type === "live" && `"Nova aula ao vivo adicionada com sucesso!"`) ||
        "";

      setOkAlertMessage(alertMessage);
      setShowOkAlert(true);
    } catch (error) {
      console.error;
    }
  };

  const updateCard = async (data) => {
    try {
      setIsSaving(true);
      const cardsRef = doc(
        firestore,
        "categories",
        categoryId,
        "cards",
        cardId
      );
      await updateDoc(cardsRef, data);
    } catch (error) {
      console.error("Failed to update card.", error);
    } finally {
      setIsSaving(false);
    }
  };

  const updateVideo = async (data) => {
    try {
      setIsSaving(true);
      const videoRef = doc(
        firestore,
        "categories",
        categoryId,
        "cards",
        cardId,
        "videos",
        selectedVideoAdmin.docId
      );
      await updateDoc(videoRef, data);
    } catch (error) {
      console.error("Failed to update video.", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleEditVideoClick = (video) => {
    setSelectedVideoId(video.docId);
    setShowEditVideoModal(true);
  };

  const handleEditVideoBackgroundClick = (video) => {
    setSelectedVideoId(video.docId);
    setShowVideoBackgroundUploadModal(true);
  };

  // Isso aqui da pra melhorar, mas vai ficar assim por enquanto.
  const selectedVideoAdmin = videos.find((video) => video.docId === selectedVideoId);


  if (isLoading) {
    return (
      <ActivityIndicator
        style={{
          flex: 1,
          backgroundColor: THEME.COLORS.BACKGROUND_MAIN,
          justifyContent: "center",
        }}
        color={THEME.COLORS.PRIMARY_800}
      />
    );
  }

  const OutsideView = () => {
    const { width } = useViewport();
    const breakpoint = 1080;

    return width < breakpoint ? (
      <>
        <View
          style={{
            alignSelf: "center",
            width: "100%",
            height: ((width) - 32) / 1.78,
            marginBottom: "1rem",
            paddingHorizontal: "2%",
          }}
        >
          <ViewVideo
            user={user}
            card={card}
            navigation={navigation}
            firstPlanPurchase={firstPlanPurchase}
            guruUserToken={guruUserToken}
          />
        </View>
        <ViewFlatList
          card={card}
          videos={videos}
          user={user}
          onVideoListClick={handleSelectedVideoChange}
        />
      </>
    ) : (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          paddingHorizontal: "2%",
          paddingVertical: "2rem",
        }}
      >
        <View
          style={{
            alignSelf: "center",
            height: ((width * 0.70) - 32) / 1.78,
            width: "70%",
          }}
        >
          <ViewVideo
            user={user}
            card={card}
            navigation={navigation}
            firstPlanPurchase={firstPlanPurchase}
            guruUserToken={guruUserToken}
          />
        </View>
        <ScrollView showsHorizontalScrollIndicator={true}
          style={{
            alignSelf: "left",
            width: "30%",
            height: ((width * 0.70) - 32) / 1.78,
            paddingLeft: "1rem",
          }}
        >
          <ViewFlatList
            card={card}
            videos={videos}
            user={user}
            onVideoListClick={handleSelectedVideoChange}
          />
        </ScrollView>
      </View>
    );
  };

  const OutsideViewAdmin = () => {
    const { width } = useViewport();
    const breakpoint = 1080;

    return width < breakpoint ? (
      <>
        <View
          style={{
            alignSelf: "center",
            width: "100%",
            height: ((width) - 32) / 1.78,
            marginBottom: "1rem",
            paddingHorizontal: "2%",
          }}
        >
          <ViewImage
            img={cardBackgroundImageUrl}
            showModal={() => setShowCardBackgroundUploadModal(true)}
          />
        </View>
        <FlatListView
          videos={videos}
          user={user}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          onDeleteVideoClick={handleOnDeleteVideoClick}
          setVideoIndexToBeDeleted={{}}
          onAddVideoClick={addVideo}
          cardInformation={card}
          onDeleteCardClick={() => setShowDeleteCardModal(true)}
          onEditCardInformationClick={() => setShowEditCardModal(true)}
          onEditOrderClick={() => setShowEditOrderModal(true)}
          onEditVideoClick={handleEditVideoClick}
          onEditVideoBackgroundClick={handleEditVideoBackgroundClick}
          onVideoListClick={handleSelectedVideoChange}
        />
      </>
    ) : (
      <View
        style={{
          flexDirection: "row",
          paddingHorizontal: "2%",
          flex: 1
        }}
      >
        <View
          style={{
            height: ((width * 0.70) - 32) / 1.78,
            width: "70%",
          }}
        >
          <ViewImage
            img={cardBackgroundImageUrl}
            showModal={() => setShowCardBackgroundUploadModal(true)}
          />
        </View>
        <FlatListView
          videos={videos}
          user={user}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          onDeleteVideoClick={handleOnDeleteVideoClick}
          setVideoIndexToBeDeleted={{}}
          onAddVideoClick={addVideo}
          cardInformation={card}
          onDeleteCardClick={() => setShowDeleteCardModal(true)}
          onEditCardInformationClick={() => setShowEditCardModal(true)}
          onEditOrderClick={() => setShowEditOrderModal(true)}
          onEditVideoClick={handleEditVideoClick}
          onEditVideoBackgroundClick={handleEditVideoBackgroundClick}
          onVideoListClick={handleSelectedVideoChange}
        />

      </View>
    );
  };

  return user?.isAdmin ? (
    <Provider>
      <ViewPortProvider>
        <SafeAreaView
          style={{ backgroundColor: THEME.COLORS.BACKGROUND_MAIN, flexGrow: 1, alignContent: "flex-start" }}
        >
          <Header
            onPress={() => {
              navigation.dispatch(
                CommonActions.reset({
                  routes: [{ name: "Painel" }],
                })
              );
            }}
            user={user}
          />
          <OutsideViewAdmin />
          <Modal
            animationType="fade"
            visible={showEditCardModal}
          >
            <EditCardInformationModal
              isSaving={isSaving}
              onClose={() => setShowEditCardModal(false)}
              onSave={updateCard}
              card={card}
              plans={plans}
              courses={courses}
              products={products}
            />
          </Modal>
          <Modal
            animationType="fade"
            visible={showCardBackgroundUploadModal}
          >
            <ImageUpload
              onClose={() => setShowCardBackgroundUploadModal(false)}
              metadata={{
                databasePath: `categories/${categoryId}/cards/${cardId}`,
                databasePropName: "backgroundImage",
              }}
            />
          </Modal>
          <Modal
            animationType="fade"
            visible={showVideoBackgroundUploadModal}
          >
            <ImageUpload
              onClose={() => setShowVideoBackgroundUploadModal(false)}
              metadata={{
                databasePath: `categories/${categoryId}/cards/${cardId}/videos/${selectedVideoId}`,
                databasePropName: "backgroundImage",
              }}
            />
          </Modal>
          <Modal
            animationType="fade"
            visible={showEditVideoModal}
          >
            <EditVideo
              categoryId={categoryId}
              cardId={cardId}
              videoId={selectedVideoId}
              planType={planType}
              maybeRenderImage={"EditVideo"}
              video={selectedVideoAdmin}
              onSave={updateVideo}
              onClose={() => setShowEditVideoModal(false)}
              user={user}
            />
          </Modal>
          <Modal
            animationType="fade"
            visible={showEditOrderModal}
          >
            <EditOrder
              videos={videos}
              isSaving={isSaving}
              onClose={() => setShowEditOrderModal(false)}
              onSave={updateVideoOrder}
            />
          </Modal>
          <AlertBox
            title={
              "Você TEM CERTEZA que deseja excluir esse vídeo ou aula ao vivo?"
            }
            message={
              "Ao excluir, todos os conteúdos correspondentes a este vídeo ou aula ao vivo serão removidos permanentemente (isso inclui vídeo, áudio, PDF e imagem de capa)"
            }
            visible={showDeleteVideoModal}
            leftButton={"Sim"}
            rightButton={"Não"}
            rightButtonFunction={() => setShowDeleteVideoModal(false)}
            leftButtonFunction={handleOnDeleteVideoConfirm}
            onClose={() => setShowDeleteVideoModal(false)}
          />
          <AlertBox
            message={okAlertMessage}
            visible={showOkAlert}
            leftButtonFunction={() => setShowOkAlert(false)}
            onClose={() => setShowOkAlert(false)}
            leftButton={"OK"}
          />
          <AlertBox
            title={"Você TEM CERTEZA que deseja excluir esse card inteiro?"}
            message={
              "Ao excluir, todos os conteúdos correspondentes a este card serão removidos permanentemente (isso inclui todos os vídeos, áudios, PDFs e imagens de capa)"
            }
            visible={showDeleteCardModal}
            leftButton={"Sim"}
            rightButton={"Não"}
            rightButtonFunction={() => setShowDeleteCardModal(false)}
            leftButtonFunction={handleOnDeleteCardConfirm}
            onClose={() => setShowDeleteCardModal(false)}
          />
          {isLoading && (
            <ActivityIndicator
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                alignItems: "center",
                justifyContent: "center",
              }}
              size="large"
              color={THEME.COLORS.PRIMARY_800}
            />
          )}
        </SafeAreaView>
      </ViewPortProvider>
    </Provider >
  ) : (
    <ViewPortProvider>
      <SafeAreaView
        style={{ backgroundColor: THEME.COLORS.BACKGROUND_MAIN, flexGrow: 1 }}
      >
        <Header
          onPress={() => {
            navigation.dispatch(
              CommonActions.reset({
                routes: [{ name: "Conteudo" }],
              })
            );
          }}
          user={user}
        />
        <OutsideView />
      </SafeAreaView>
    </ViewPortProvider>
  );
}