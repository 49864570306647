import { ref, uploadBytesResumable } from "firebase/storage";
import React, { useState } from "react";
import { StyleSheet, View, Pressable } from "react-native";
import { storage } from "../../services/firebase";
import {
  StandardText,
  FooterText,
  SmallText,
} from "../../config/theme/globalStyles";
import Button from "../Button";
import THEME from "../../config/theme";
import { MaterialIcons, FontAwesome, Ionicons } from "@expo/vector-icons";
import {
  Container,
  Content,
  ContentIcon,
  ViewPressable,
  Wrapper,
  ViewSuccess,
} from "./style";
import { firestoreAutoId } from "../../utils";
import { AlertBox } from "../AlertBox";
import { Provider } from "react-native-paper";

export function ImageUpload(props) {
  const { onClose, metadata } = props;
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const [visibleAlert, setVisibleAlert] = useState(false);
  const [error, setError] = useState(null);
  const [uploadCompleted, setUploadCompleted] = useState(false);

  const showAlert = (title, message) => {
    setVisibleAlert(true);
    setError({ title, message });
  };

  const handleUpload = async (event) => {
    try {
      event.preventDefault();
      setUploading(true);
      const firestoreId = firestoreAutoId();
      const file = event.target[0]?.files[0];
      if (!file) {
        showAlert("Erro", "Não há arquivo para ser enviado.");
        throw new Error("No file attached");
      }

      const name = `images/${firestoreId + file.name}`;
      const storageRef = ref(storage, name);
      const fileMeta = metadata && { customMetadata: metadata };
      const uploadTask = uploadBytesResumable(storageRef, file, fileMeta);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
          setUploading(true);
        },
        (error) => {
          setUploading(false);
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              showAlert(
                "Erro:",
                "Arquivo deve ser uma imagem em jpeg, png ou webp"
              );
              break;
            case "storage/canceled":
              showAlert("Erro:", "	O usuário cancelou a operação.");
              break;
            case "storage/unknown":
              showAlert(
                "Erro:",
                "Ocorreu um erro desconhecido, contate o suporte."
              );
              break;
          }
        },
        () => {
          setUploadCompleted(true);
          setUploading(false);
        }
      );
    } catch (error) {
      setUploading(false);
    }
  };

  return (
    <Provider>
      <Container>
        {uploadCompleted ? (
          <ViewSuccess>
            <MaterialIcons
              name="add-task"
              size={30}
              color={THEME.COLORS.TEXT_ABOUT}
            />
            <StandardText margin="1rem">
              Imagem de capa adicionada com sucesso!
            </StandardText>
          </ViewSuccess>
        ) : (
          <>
            <ViewPressable>
              <Pressable onPress={onClose}>
                <ContentIcon>
                  <Ionicons
                    name="close"
                    size={THEME.FONTSIZE.BIG}
                    color={THEME.COLORS.PRIMARY_900}
                  />
                </ContentIcon>
              </Pressable>
            </ViewPressable>

            <Wrapper>
              <Content>
                <ContentIcon>
                  <FontAwesome
                    name="exclamation-triangle"
                    size={24}
                    color="red"
                  />
                  <StandardText padding="0rem 0.5rem" color="red">
                    Atenção!
                  </StandardText>
                </ContentIcon>
                <SmallText>
                  Ao dar o upload de uma nova imagem, a anterior (se houver)
                  será removida.
                </SmallText>
                <ContentIcon>
                  <MaterialIcons name="info" size={24} color="red" />
                  <StandardText padding="0rem 0.5rem" color="red">
                    Informações importantes:
                  </StandardText>
                </ContentIcon>
                <SmallText textAlign="flex-start" padding="0.3rem 0rem">
                  Compactação: A compactação da imagem reduz o tamanho do
                  arquivo para melhorar a velocidade do seu carregamento na
                  tela.
                </SmallText>
                <SmallText textAlign="flex-start" padding="0.3rem 0rem">
                  A imagem deverá ter as seguintes especificações:
                </SmallText>
                <FooterText
                  textAlign="flex-start"
                  padding="0.1rem 0rem"
                  color={THEME.COLORS.TEXT_ABOUT}
                >
                  Formato recomendado: PNG
                </FooterText>
                <FooterText
                  textAlign="flex-start"
                  padding="0.1rem 0rem"
                  color={THEME.COLORS.TEXT_ABOUT}
                >
                  Proporção: 16:9
                </FooterText>
                <FooterText
                  textAlign="flex-start"
                  padding="0.1rem 0rem"
                  color={THEME.COLORS.TEXT_ABOUT}
                >
                  Tamanho MÁXIMO do arquivo: 2MB
                </FooterText>
                <FooterText
                  textAlign="flex-start"
                  padding="0.1rem 0rem"
                  color={THEME.COLORS.TEXT_ABOUT}
                >
                  Dimensão recomendada: 682 x 383
                </FooterText>
              </Content>
              <View style={{ justifyContent: "center", alignItems: "center" }}>
                <form onSubmit={handleUpload}>
                  <input type="file" style={{ margin: "1rem" }}></input>
                  <button type="submit">Enviar</button>
                </form>
              </View>
              <AlertBox
                title={error?.title}
                message={error?.message}
                visible={visibleAlert}
                onClose={onClose}
                leftButtonFunction={onClose}
                leftButton={"OK"}
              ></AlertBox>
            </Wrapper>

          </>
        )}

        {uploadCompleted && (
          <View>
            <Button onPress={onClose} title="OK" />
          </View>
        )}
        {uploading && (
          <View
            style={[
              StyleSheet.absoluteFill,
              {
                backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                alignItems: "center",
                justifyContent: "center",
              },
            ]}
          >
            <SmallText margin="1rem">Carregando...</SmallText>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <progress value={progress} max="100" />
              <FooterText color={THEME.COLORS.TEXT_ABOUT} margin="0rem 1rem">
                {progress}%
              </FooterText>
            </View>
          </View>
        )}
      </Container>
    </Provider>
  );
}
