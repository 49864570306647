import React, { useState, useEffect } from "react";
import { Provider } from "react-native-paper";
import Header from "../../components/Header";
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { firestore } from "../../services/firebase";
import "firebase/functions";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  Content,
  ViewText,
  ViewTextInput,
  ViewDescription,
  ViewButton,
  ViewPlan,
  ViewHelper,
  VerticalScroll,
} from "./style";
import {
  Container,
  SmallText,
  StandardText,
} from "../../config/theme/globalStyles";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import THEME from "../../config/theme";
import { AlertBox } from "../../components/AlertBox";
import { nameValidator, cellphoneValidator } from "../../utils";
import { HelperText } from "react-native-paper";
import { SafeAreaView } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { GuruSSO } from "../../services/guru";

const auth = getAuth();
const functions = getFunctions();

export function EditProfile({ navigation }) {
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingPlan, setLoadingPlan] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);

  const [errorCellphone, setErrorCellphone] = useState("");
  const [errorName, setErrorName] = useState('')

  const [message, setMessege] = useState(null);

  const { Nome_Completo, Email, Celular, CPF, Doc, plan } = user || {};

  const showAlert = (message) => {
    setVisibleAlert(true);
    setMessege(message);
  };

  const hideAlert = (status) => {
    setVisibleAlert(status);
  };

  const validation = () => {
    let error = false;
    const nameError = nameValidator(Nome_Completo);
    const cellphoneError = cellphoneValidator(Celular);

    if (nameError || cellphoneError) {
      let error = true;
      setErrorName(nameError);
      setErrorCellphone(cellphoneError);
      setLoadingSave(false);
      return error;
    }
    return error;
  };

  const getUser = async () => {
    const id = auth?.currentUser?.uid;
    if (!id) return;
    const docRef = doc(firestore, "users", id);
    const docSnap = await getDoc(docRef);

    setIsLoading(false);

    if (!docSnap.exists()) {
      console.log("No such document!");
      return;
    }

    setUser({
      id,
      ...docSnap.data(),
    });
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user && !user.isAnonymous) {
        await getUser(user.uid);
      } else if (!user) {
        // Nenhum usuário está logado, realizar login anônimo.
        signInAnonymously(auth)
          .then(() => {
            setIsLoading(false);
            // Login anônimo realizado com sucesso.
          })
          .catch((error) => {
            // Houve um erro ao realizar login anônimo.
            console.error(error);
          });
      }
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <ActivityIndicator
        style={{
          flex: 1,
          backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
          justifyContent: "center",
        }}
        color={THEME.COLORS.PRIMARY_800}
      />
    );
  }

  async function sendToCustomerPortal() {
    const functionRef = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );
    setLoadingPlan(true);
    const { data } = await functionRef({
      returnUrl: window.location.origin,
      locale: "auto",
    }).finally(() => {
      setLoadingPlan(false);
    });
    window.location.assign(data.url);
  }

  async function sendToCustomerPortalOfGuru() {

    await GuruSSO()
  }

  const salvar = () => {
    if (validation() == false) {
      setLoadingSave(true);
      const userRef = doc(firestore, "users", user.id);
      setDoc(
        userRef,
        {
          Nome_Completo,
          Celular,
        },
        { merge: true }
      )
        .then(() => {
          showAlert("Concluído! Seus dados foram salvos com sucesso!");
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoadingSave(false);
        });
    }
  };
  return (
    <Provider>
      <SafeAreaView
        style={{
          flexGrow: "1",
          backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
        }}
      >
        {user ? (
          <VerticalScroll>
            <Header
              onPressEditProfile={() => {
                navigation.navigate("Conteudo", { screen: "Aplicativo" });
              }}
              user={user}
            />
            {user?.plan && user?.stripeId && (
              <ViewPlan>
                <StandardText padding="0rem 0rem 0.5rem 0rem" textAlign="left">
                  DETALHES DO PLANO:
                </StandardText>
                <ViewButton>
                  <Button
                    title={"Editar meu plano"}
                    isLoading={loadingPlan}
                    onPress={sendToCustomerPortal}
                    borderRadius="5px"
                    colorbutton={THEME.COLORS.PRIMARY_800}
                    colortitle={THEME.COLORS.TEXT_BUTTON}
                  ></Button>
                </ViewButton>
                {user?.plan?.length === 1 &&
                  <SmallText textAlign="left">ATENÇÃO: Ocorreu algum erro no pagamento do seu cartão ou você possui um boleto pendente! Por favor, verifique seu e-mail e normalize a situação, para poder retornar o acesso aos conteúdos no app.</SmallText>
                }
              </ViewPlan>
            )}
            {user?.guruId && (
              <ViewPlan>
                <ViewButton>
                  <Button
                    title={"Gerenciar minhas compras"}
                    isLoading={loadingPlan}
                    onPress={sendToCustomerPortalOfGuru}
                    borderRadius="5px"
                    colorbutton={THEME.COLORS.PRIMARY_800}
                    colortitle={THEME.COLORS.TEXT_BUTTON}
                  ></Button>
                </ViewButton>
              </ViewPlan>
            )}
            <Content>
              <StandardText padding="1rem 0rem" textAlign="left">
                EDITAR INFORMAÇÕES DO CADASTRO:
              </StandardText>
              <ViewDescription>
                <SmallText textAlign="left">Nome Completo:</SmallText>
              </ViewDescription>
              <ViewTextInput>
                <ViewText>
                  <TextInput
                    placeholder="Nome"
                    keyboardType="default"
                    returnKeyType="go"
                    value={Nome_Completo}
                    onChange={({ target: { value } }) => {
                      setUser({ ...user, Nome_Completo: value });
                      setErrorName(null);
                    }}
                    error={!!errorName}
                  />
                </ViewText>
                <ViewHelper>
                  <HelperText type="error" visible={errorName}>
                    {errorName}
                  </HelperText>
                </ViewHelper>
              </ViewTextInput>
              <ViewDescription>
                <SmallText textAlign="left">Email:</SmallText>
              </ViewDescription>
              <ViewTextInput>
                <ViewText>
                  <TextInput value={Email} editable={false} />
                </ViewText>
                <ViewHelper></ViewHelper>
              </ViewTextInput>
              <ViewDescription>
                <SmallText textAlign="left">Celular:</SmallText>
              </ViewDescription>
              <ViewTextInput>
                <ViewText>
                  <TextInput
                    placeholder="(DDD)99999-9999"
                    keyboardType="default"
                    returnKeyType="go"
                    value={Celular}
                    onChange={({ target: { value } }) => {
                      setUser({ ...user, Celular: value });
                      setErrorCellphone(null);
                    }}
                    error={!!errorCellphone}
                  />
                </ViewText>
                <ViewHelper>
                  <HelperText type="error" visible={errorCellphone}>
                    {errorCellphone}
                  </HelperText>
                </ViewHelper>
              </ViewTextInput>
              <ViewDescription>
                <SmallText textAlign="left">{CPF ? "CPF:" : "Doc"}</SmallText>
              </ViewDescription>
              <ViewTextInput>
                <ViewText>
                  <TextInput value={CPF ? CPF : Doc} editable={false} />
                </ViewText>
                <ViewHelper></ViewHelper>
              </ViewTextInput>
              {visibleAlert && (
                <AlertBox
                  message={message}
                  visible={visibleAlert}
                  onClose={hideAlert}
                ></AlertBox>
              )}
              <ViewButton>
                <Button
                  title={"SALVAR"}
                  isLoading={loadingSave}
                  onPress={salvar}
                  borderRadius="5px"
                  colorbutton={THEME.COLORS.PRIMARY_800}
                  colortitle={THEME.COLORS.TEXT_BUTTON}
                ></Button>
              </ViewButton>
            </Content>
          </VerticalScroll>
        ) : (
          <Container justifyContent="center">
            <StandardText>Ops, acesso negado!</StandardText>
            <SmallText margin="2rem">Se você já possui cadastro, clique no botão abaixo para fazer login com o seu email e senha:</SmallText>
            <Button
              title={"Login"}
              onPress={() => navigation.navigate("Login")}
              colorbutton={THEME.COLORS.PRIMARY_800}
              colortitle={THEME.COLORS.TEXT_BUTTON}
            ></Button>
          </Container>
        )}
      </SafeAreaView>
    </Provider>
  );
}