import React from "react";
import { FlatList, TouchableOpacity } from "react-native";
import { VerticalScroll, VerticalScrollDesktop } from "../style";

import PlayList from "../../../components/PlayList";
import useViewport from "../../../hooks/useViewport";
import THEME from "../../../config/theme"
import { Title, StandardText } from "../../../config/theme/globalStyles";

const ViewFlatList = (props) => {
  const { user, card, videos, onVideoListClick } = props;
  const { coursesCard, plans, description, title, productIds } = card || {};
  const { plan: userPlan, courses: userCourses, isAdmin, productIds: userProductIds } = user || {};

  const { width } = useViewport();
  const breakpoint = 1080;
  const Container = width < breakpoint ? VerticalScroll : VerticalScrollDesktop;

  const toDate = (seconds) => {
    const date = new Date(1970, 0, 1);
    date.setSeconds(seconds);
    return date;
  };

  const ListHeader = ({ title, description }) => {
    return (
      <>
        <Title color={THEME.COLORS.TITLE_MAIN} textAlign="left">
          {title}
        </Title>
        <StandardText
          color={THEME.COLORS.TEXT_MAIN}
          textAlign="left"
          margin="1rem 0rem 0rem 0rem"
          fontFamily={THEME.FONTFAMILY.LIGHT}
        >
          {description}
        </StandardText>
      </>
    );
  };

  return (
    <Container>
      {(productIds &&
          productIds?.length > 0 &&
          productIds?.filter(cardProductId => {
            const matchingUserProducts = userProductIds?.filter(userProduct => userProduct.productId === cardProductId);
            if (!matchingUserProducts || matchingUserProducts.length === 0) {
              return false; // O usuário não possui este produto, então não tem acesso
            }
            // Se não houver data de expiração, ou se alguma data de expiração for maior que a data atual, o usuário tem acesso
            return matchingUserProducts.some(userProduct => !userProduct.expirationDate || toDate(userProduct.expirationDate.seconds) > new Date());
          }).length > 0
        ) ||
      (plans &&
        plans.length > 0 &&
        plans.filter((plan) => (plan.priceId || plan.productId) == userPlan).length > 0) ||
        (userCourses &&
          userCourses.length > 0 &&
          userCourses.filter(
            (userCourse) =>
              coursesCard?.some(
                (itemToBeRemoved) =>
                  (itemToBeRemoved.priceId || itemToBeRemoved.productId) === (userCourse.priceId || userCourse.productId)
              ) && toDate(userCourse.dueDate.seconds) > new Date()
          ).length > 0) ||
        (plans == null && coursesCard == null  && productIds == null) || isAdmin === true ? (
        <FlatList
          data={videos}
          keyExtractor={(item) => item.docId}
          renderItem={({ item, index }) => (
            <TouchableOpacity
              style={{ marginVertical: "0.8rem" }}
              key={item.key}
              onPress={() => onVideoListClick(item)}
            >
              <PlayList {...item} user={user}></PlayList>
            </TouchableOpacity>
          )}
          style={{ flexGrow: 0 }}
          ListHeaderComponent={
            <ListHeader
              title={title}
              description={description}
            ></ListHeader>
          }
        />
      ) : (
        <FlatList
          data={videos}
          keyExtractor={(item) => item.docId}
          renderItem={({ item, index }) => (
            <TouchableOpacity
              style={{ marginVertical: "0.8rem" }}
              key={item.key}
            >
              <PlayList {...item} user={user}></PlayList>
            </TouchableOpacity>
          )}
          style={{ flexGrow: 0 }}
          ListHeaderComponent={
            <ListHeader
              title={title}
              description={description}
            ></ListHeader>
          }
        />
      )}
    </Container>
  );
};

export default ViewFlatList;
