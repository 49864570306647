import React from "react";
import { useNavigation } from "@react-navigation/native";
import { Image, Touchable, ViewLock, ContentText, ViewCommentNotification } from "./style";
import THEME from "../../config/theme";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { SmallText, FooterText } from "../../config/theme/globalStyles";
import { View } from "react-native";
import Svg, { Path } from 'react-native-svg';

const CategoryItem = ({
  categoryId,
  item,
  user,
}) => {
  const navigation = useNavigation();

  const onCardPress = () => {
    if (!item?.videoId) {
      navigation.navigate("Modulo", {
        cardId: item.id ? item.id : item.cardId,
        categoryId: categoryId ? categoryId : item.categoryId,
        ...(user?.firstPlanPurchase && { firstPlanPurchase: user?.firstPlanPurchase }),
      });
    } else if (item?.videoId) {
      navigation.navigate("Aula", {
        cardId: item.cardId,
        categoryId: item.categoryId,
        selectedVideo: item.videoId
      });
    }
  };

  const toDate = (seconds) => {
    const date = new Date(1970, 0, 1);
    date.setSeconds(seconds);
    return date;
  };

  return (
    <Touchable onPress={onCardPress}>
      <Image source={{ uri: item?.backgroundImage?.url || item?.img }}>
        {item.archived == false && user?.isAdmin && (
          <ContentText backgroundColor="white">
            <FooterText margin="0rem 1rem" color={THEME.COLORS.TEXT_ABOUT} fontFamily={THEME.FONTFAMILY.MEDIUM}>{item.order}</FooterText>
            <FooterText color="black" fontFamily={THEME.FONTFAMILY.MEDIUM}>Publicado</FooterText>
          </ContentText>
        )}
        {item.archived == true && user?.isAdmin && (
          <ContentText backgroundColor="black">
            <FooterText margin="0rem 1rem" color={THEME.COLORS.TEXT_MAIN} fontFamily={THEME.FONTFAMILY.MEDIUM}>{item.order}</FooterText>
            <FooterText color="white" fontFamily={THEME.FONTFAMILY.MEDIUM}>Arquivado</FooterText>
          </ContentText>
        )}
        {item.hasNewComment == true && user?.isAdmin && (
          <ViewCommentNotification>
            <MaterialCommunityIcons
              name="comment-eye"
              size={24}
              color={THEME.COLORS.PRIMARY_900}
              style={{ padding: "0.2rem" }}
            />
          </ViewCommentNotification>
        )}
        {(item?.productIds &&
          item?.productIds?.length > 0 &&
          item?.productIds?.filter(cardProductId => {
            const matchingUserProducts = user?.productIds?.filter(userProduct => userProduct.productId === cardProductId);
            if (!matchingUserProducts || matchingUserProducts.length === 0) {
              return false; // O usuário não possui este produto, então não tem acesso
            }
            // Se não houver data de expiração, ou se alguma data de expiração for maior que a data atual, o usuário tem acesso
            return matchingUserProducts.some(userProduct => !userProduct.expirationDate || toDate(userProduct.expirationDate.seconds) > new Date());
          }).length > 0
        ) ||
          (item?.plans &&
            item.plans?.length > 0 &&
            item.plans?.filter((item) => (item.priceId || item.productId) == user?.plan).length > 0) ||
          (user?.courses?.length > 0 &&
            user?.courses.filter(
              (userCourse) =>
                item.coursesCard?.some(
                  (itemToBeRemoved) =>
                    (itemToBeRemoved.priceId || itemToBeRemoved.productId) === (userCourse.priceId || userCourse.productId)
                ) && toDate(userCourse.dueDate.seconds) > new Date()
            ).length > 0) ||
          (item.plans == null && item.coursesCard == null && item.productIds == null) || user?.isAdmin ? (
          <div></div>
        ) : (
          <ViewLock>
            <View
              style={{
                backgroundColor: 'white',
                padding: 3,
                borderRadius: 10,
              }}
            >
              <Svg
                width={20}
                height={20}
                viewBox="0 0 16 16"
                fill="none"
              >
                <Path
                  d="M4 5.014v-.93c0-1.078.417-2.114 1.165-2.881A3.96 3.96 0 018 0a3.96 3.96 0 012.835 1.203A4.127 4.127 0 0112 4.083v.93a2.25 2.25 0 012 2.237v5.5A2.25 2.25 0 0111.75 15h-7.5A2.25 2.25 0 012 12.75v-5.5a2.25 2.25 0 012-2.236zM6.239 2.25A2.46 2.46 0 018 1.5c.657 0 1.29.267 1.761.75.471.483.739 1.142.739 1.833V5h-5v-.917c0-.69.268-1.35.739-1.833zM8 9.25a.75.75 0 00-.75.75v1a.75.75 0 001.5 0v-1A.75.75 0 008 9.25z"
                  fillRule="evenodd"
                  fill={THEME.COLORS.ICON}
                />
              </Svg>
            </View>
          </ViewLock>
        )}
      </Image>
      <SmallText
        fontSize={THEME.FONTSIZE.EXTRASMALL}
        color={THEME.COLORS.TEXT_MAIN}
        maxWidth="20rem"
        textAlign="left"
        numberOfLines={2}
      >
        {item.title}
      </SmallText>
    </Touchable>
  );
};

//masWidth do SmallText é a largura do cardItem

export default CategoryItem;
