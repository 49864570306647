import React, { useState, useEffect } from "react";
import { View } from "react-native";
import { doc, getDoc } from "firebase/firestore";
import THEME from "../../../config/theme";
import { Feather } from '@expo/vector-icons';
import { firestore } from "../../../services/firebase";
import { Avatar, ViewComment, ViewAvatar } from "../style";
import LogoMain from "../../../../assets/LogoMain.png"
import { aspectRatioLogoMain } from "../../../config/data";
import { FooterText } from "../../../config/theme/globalStyles";

const ReplyItem = ({ reply }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { isAdmin } = user || false

  const fetchUser = async () => {
    const docRef = doc(firestore, "users", reply.userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setUser(docSnap.data())
      setIsLoading(false)
    } else {
      console.log("User does not exist");
    }
  }

  useEffect(() => {
    fetchUser();
  }, []);

  return !isLoading && (
    <ViewComment>
      <ViewAvatar>
        {isAdmin ? (
          <Avatar
            resizeMode="contain"
            width={aspectRatioLogoMain * 48}
            source={LogoMain}
          />
        ) : (
          <Feather name="user" size={24} color={THEME.COLORS.PRIMARY_900} />
        )
        }
      </ViewAvatar>
      <View style={{ flex: 1, paddingLeft: 5 }}>
        <FooterText color={THEME.COLORS.TITLE_MAIN} textAlign="left" fontFamily={THEME.FONTFAMILY.MEDIUM}>
          {user?.Nome_Completo}
        </FooterText>
        <FooterText color={THEME.COLORS.TEXT_MAIN} textAlign="left">
          {reply.comment}
        </FooterText>
      </View>
    </ViewComment>
  );
};

export default ReplyItem;