import React from "react";
import { TextInput as Input } from "react-native-paper";
import THEME from "../../config/theme";

const TextInput = (props) => (
  <Input
    {...props}
    style={{
      backgroundColor: props?.backgroundColor ? props?.backgroundColor : THEME.COLORS.BACKGROUND_ABOUT,
      fontFamily: THEME.FONTFAMILY.REGULAR,
      fontSize: THEME.FONTSIZE.SMALL,
    }}
    mode="outlined"
    theme={{
      colors: {
        primary: props?.color ? props?.color : THEME.COLORS.PRIMARY_900,
        outline: props?.color ? props?.color : THEME.COLORS.PRIMARY_800,
        onSurfaceVariant: props?.color ? props?.color : THEME.COLORS.TEXT_ABOUT,
        onSurface: props?.color ? props?.color : THEME.COLORS.TEXT_ABOUT,
      },
    }}
  />
);

export default TextInput;