import React from 'react';
import { SmallText } from '../../config/theme/globalStyles';
import { Container } from './style';

const TouchableText = ({
  title, color, textDecoration, fontFamily, fontSize, margin, ...rest}) => {
  return (
    <Container
      {...rest}
    >
      <SmallText
        textDecoration={textDecoration}
        color={color}
        fontFamily={fontFamily}
        fontSize={fontSize}
        margin={margin ? margin : margin}
      >
        {title}
      </SmallText>
    </Container>
  );
};
export default TouchableText