import { ref, uploadBytesResumable } from "firebase/storage";
import React, { useState } from "react";
import { StyleSheet, View, Pressable } from "react-native";
import { storage } from "../../services/firebase";
import {
  FooterText,
  SmallText,
  StandardText,
} from "../../config/theme/globalStyles";
import Button from "../Button";
import THEME from "../../config/theme";
import { MaterialIcons, FontAwesome, Ionicons } from "@expo/vector-icons";
import { firestoreAutoId } from "../../utils";
import { AlertBox } from "../AlertBox";

import {
  Content,
  ContentIcon,
  Container,
  ViewPressable,
  Wrapper,
  ViewSuccess,
} from "./style";

export function PdfUpload(props) {
  const { onUploadCompleted, onClose, metadata } = props;
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadCompleted, setUploadCompleted] = useState(false);

  const [error, setError] = useState(null);
  const [visibleAlert, setVisibleAlert] = useState(false);

  const showAlert = (title, message) => {
    setVisibleAlert(true);
    setError({ title, message })
  };

  const Close = () => {
    return (
      <ContentIcon>
        <Ionicons
          name="close"
          handleUpload
          size={THEME.FONTSIZE.BIG}
          color={THEME.COLORS.PRIMARY_900}
        />
      </ContentIcon>
    );
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    try {
      const firestoreId = firestoreAutoId();
      const file = event.target[0]?.files[0];
      setUploading(true);

      if (!file) {
        throw new Error("No file attached");
      }
      const name = `pdfs${firestoreId + file.name}`;
      const storageRef = ref(storage, name);
      const fileMeta = metadata && { customMetadata: metadata };
      const uploadTask = uploadBytesResumable(storageRef, file, fileMeta);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          setUploading(false);
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              showAlert(
                "Erro:",
                "Arquivo deve ser em formato pdf"
              );
              break;
            case "storage/canceled":
              showAlert("Erro:", "	O usuário cancelou a operação.");
              break;
            case "storage/unknown":
              showAlert(
                "Erro:",
                "Ocorreu um erro desconhecido, contate o suporte."
              );
              break;
            case "storage/object-not-found":
              showAlert(
                "Erro:",
                "Nenhum objeto existe na referência desejada."
              );
              break;
          }
        },
        async () => {
          // Upload completed successfully, now we can get the download URL
          setUploadCompleted(true);
          setUploading(false);
          onUploadCompleted();
        }
      );
    } catch (error) {
      setUploading(false);
    }
  };

  return (
    <Container>
      {uploadCompleted ? (
        <ViewSuccess>
          <MaterialIcons
            name="add-task"
            size={30}
            color={THEME.COLORS.TEXT_ABOUT}
          />
          <StandardText margin="1rem">PDF adicionado com sucesso!</StandardText>
        </ViewSuccess>
      ) : (
        <>
          <ViewPressable>
            <Pressable onPress={onClose}>
              <Close></Close>
            </Pressable>
          </ViewPressable>
          <Wrapper>
            <Content>
              <ContentIcon>
                <FontAwesome
                  name="exclamation-triangle"
                  size={30}
                  color="red"
                />
                <StandardText padding="0rem 0.5rem" color="red">
                  Atenção!
                </StandardText>
              </ContentIcon>
              <SmallText>
                Ao dar o upload em um novo PDF, o anterior (se houver) será
                removido.
              </SmallText>
            </Content>
            <View style={{ justifyContent: "center", alignItems: "center" }}>
              <form onSubmit={handleUpload}>
                <input type="file" style={{ margin: "1rem" }}></input>
                <button type="submit">Enviar</button>
              </form>
            </View>
          </Wrapper>
          <AlertBox
            title={error?.title}
            message={error?.message}
            visible={visibleAlert}
            onClose={onClose}
            leftButtonFunction={onClose}
            leftButton={"OK"}
          ></AlertBox>
        </>
      )}
      {uploadCompleted && <Button onPress={onClose} title="OK" />}
      {uploading && (
        <View
          style={[
            StyleSheet.absoluteFill,
            {
              backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
              alignItems: "center",
              justifyContent: "center",
            },
          ]}
        >
          <SmallText margin="1rem">Carregando...</SmallText>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <progress value={progress} max="100" />
            <FooterText color={THEME.COLORS.TEXT_ABOUT} margin="0rem 1rem">
              {progress}%
            </FooterText>
          </View>
        </View>
      )}
    </Container>
  );
}
