import React from "react";
import { Container } from "./style";
import { StandardText } from "../../config/theme/globalStyles";
import { ActivityIndicator } from "react-native-paper";
import THEME from "../../config/theme";
import { borderRadiusButtons } from "../../config/data";

const Button = ({
  colortitle,
  borderRadius,
  colorbutton,
  title,
  width,
  fontFamily,
  fontSize,
  margin,
  isLoading = false,
  disabled, 
  ...rest
}) => {
  const enabled = disabled ? !isLoading && !disabled : !isLoading

  return (
    <Container
      enabled={enabled}
      colorbutton={colorbutton ? colorbutton : THEME.COLORS.PRIMARY_900}
      width={width}
      margin={margin}
      borderRadius={borderRadius ? borderRadius : borderRadiusButtons}
      style={{ opacity: isLoading === true ? 0.5 : 1 }}
      {...rest}
    >
      {isLoading ? (
        <ActivityIndicator color={THEME.COLORS.TEXT_BUTTON} />
      ) : (
        <StandardText
          style={{
            color: colortitle ? colortitle : THEME.COLORS.TEXT_BUTTON,
            fontFamily: fontFamily ? fontFamily : THEME.FONTFAMILY.BOLD,
            fontSize: fontSize ? fontSize : THEME.FONTSIZE.MEDIUM,
          }}
        >
          {title}
        </StandardText>
      )}
    </Container>
  )
};

export default Button;