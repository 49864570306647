import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { firestore } from "../../../services/firebase/index";
import THEME from "../../../config/theme";
import { ActivityIndicator } from "react-native-paper";
import { Container, ContentIcon } from "./style";
import TouchableText from "../../../components/TouchableText";
import { useNavigation } from "@react-navigation/native";
import Table from "./DataTable";
import {
    getDocs,
    collection,
    query,
    getDoc,
    doc,
    where,
    setDoc,
    Timestamp,
} from "firebase/firestore";
import CreateUserModal from "./CreateUserModal";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

const auth = getAuth();

export function AppUsers() {
    const navigation = useNavigation();

    const [allUsers, setAllUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(true);
    const [isCreateUserModalVisible, setIsCreateUserModalVisible] = useState(false);
    const [products, setProducts] = useState([]);

    const getUserAdmin = async (userId) => {
        const docRef = doc(firestore, "users", userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setUser(docSnap.data())
        }
    };

    const fetchProducts = async (productIds) => {
        const productsCollection = collection(firestore, 'products');
        const q = query(productsCollection, where("marketplace", "==", "Guru Digital"));
        const querySnapshot = await getDocs(q);
        const products = [];

        querySnapshot.forEach(doc => {
            const productData = doc.data();
            const productId = productData?.productId;
            products.push({
                ...productData,
                selected: false,
            });
        });

        setProducts(products)
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (authUser) => {
            if (authUser && !authUser.isAnonymous) {
                const fetchUsers = async () => {
                    try {

                        const usersCollection = collection(firestore, 'users');
                        const q = query(usersCollection, where("isSignUpFromApp", "==", true));
                        const response = await getDocs(q);

                        const usersRecords = [];

                        response.forEach(doc => {
                            const userData = doc.data();
                            usersRecords.push({
                                id: doc.id,
                                ...userData,
                            });
                        });

                        const q2 = query(usersCollection, where("isSignUpFromAdminPanel", "==", true));
                        const response2 = await getDocs(q2);

                        const usersRecords2 = [];

                        response2.forEach(doc => {
                            const userData = doc.data();
                            usersRecords2.push({
                                id: doc.id,
                                ...userData,
                            });
                        });

                        // Combinar os resultados das duas queries
                        const allUsers = [...usersRecords, ...usersRecords2];

                        setAllUsers(allUsers);
                        getUserAdmin(authUser.uid);
                    } catch (error) {
                        console.error("Error fetching users:", error);
                    } finally {
                        setLoading(false);
                    }
                };

                fetchUsers();
            }
            else {
                setLoading(false);
            }
        });

        fetchProducts()

        return () => {
            unsubscribe();
        };
    }, []);

    const handleSaveUser = async ({ email, selectedProducts, endDate }) => {
        try {
            // Verificar se o usuário já existe no Firestore
            const usersCollection = collection(firestore, 'users');
            const q = query(usersCollection, where("Email", "==", email));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                // Se o usuário já existir, obter o ID do documento do usuário
                const userId = querySnapshot.docs[0].id;

                // Obter os productIds existentes do usuário
                const existingUserDoc = querySnapshot.docs[0];
                const existingProductIds = existingUserDoc.data().productIds || [];

                // Filtrar apenas os novos produtos selecionados
                const newSelectedProductIds = selectedProducts
                    .filter(product => product.selected)
                    .map(product => ({
                        productId: product.productId,
                        expirationDate: Timestamp.fromDate(new Date(endDate))
                    }));

                // Combinar os productIds existentes com os novos productIds selecionados
                const combinedProductIds = [
                    ...existingProductIds,
                    ...newSelectedProductIds.filter(newProduct => !existingProductIds.some(existingProduct => existingProduct.productId === newProduct.productId))
                ];

                // Atualizar o documento do usuário no Firestore com os productIds combinados
                await setDoc(doc(firestore, 'users', userId), { productIds: combinedProductIds }, { merge: true });
            } else {
                // Se o usuário não existir, criar um novo usuário como antes
                const createUserFunction = httpsCallable(
                    functions,
                    "createUser"
                );
                await createUserFunction({ email, selectedProducts, endDate });
            }

            setIsCreateUserModalVisible(false)
        } catch (error) {
            console.error('Erro ao salvar usuário:', error);
        }
    };

    return loading ? (
        <ActivityIndicator
            style={{
                flex: 1,
                backgroundColor: "#FFFFFF",
                justifyContent: "center",
            }}
            color={THEME.COLORS.PRIMARY_900}
            size="large"
        />
    ) : (
        <Container>
            <Header navigation={navigation} user={user} />
            <ContentIcon>
                <TouchableText
                    onPress={() => setIsCreateUserModalVisible(true)}
                    title="+"
                    color="#ffffff"
                    fontFamily={THEME.FONTFAMILY.BOLD}
                    fontSize={THEME.FONTSIZE.BIG}
                    margin="0rem 1rem"
                ></TouchableText>
            </ContentIcon>
            {isCreateUserModalVisible &&
                <CreateUserModal
                    products={products}
                    visible={isCreateUserModalVisible}
                    onClose={() => setIsCreateUserModalVisible(false)}
                    onSave={handleSaveUser}
                />
            }
            <Table tableData={allUsers} user={user} />
        </Container>
    );
}