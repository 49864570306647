import { auth, firestore } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

export const GuruSSO = async () => {
  try {
    const id = auth?.currentUser?.uid;
    if (!id) {
      throw 'Usuário não encontrado';
    }
    const docRef = doc(firestore, "users", id);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      throw 'Usuário não encontrado';
    }

    const user = docSnap.data();
    console.log('user >> ', user);
    if (!user.Email || user.Email == '') {
      throw 'E-mail de usuário inválido';
    }

    const guruSSOEventHandler = httpsCallable(functions, "guruSSOEventHandler");
    const response = JSON.parse((await guruSSOEventHandler({ email: user.Email })).data);

    console.log('response >> ', response);
    if (!response.token) {
      throw 'Não foi encontrado token';
    }
    const newWindow = window.open(`https://digitalmanager.guru/myorders/sso/${response.token}`, '_blank');

    if (newWindow) {
      newWindow.focus();
    }
  } catch (error) {
    console.error('Erro GURU SSO >> ' + error);
    alert('Esse código de produto não foi encontrado na sua conta da Guru Digital');
    return;
  }
}

export const getProductById = async (id) => {
  try {
    if (!id || id == '') {
      throw 'ID Informado não pode ser vázio';
    }

    // BUSQUEI NA GURU TODOS OS PRODUTOS
    const guruGetProductsEventHandler = httpsCallable(functions, "guruGetProductsEventHandler");
    const productsArrayResponse = JSON.parse((await guruGetProductsEventHandler()).data);
    console.log('procutsArrayResponse >> ', productsArrayResponse);

    if (productsArrayResponse?.data?.length == 0) {
      throw 'Lista de produtos está vazia';
    };

    // BUSQUEI SE ID INFORMADO EXISTE NO RETORNO
    const product = productsArrayResponse?.data.find(item => item.marketplace_id.toString() == id.toString());

    if (!product) {
      throw 'ID Informado não foi encontrado nos produtos existentes';
    }

    // BUSCO OFERTAS COM BASE NO ID
    const guruGetOffersEventHandler = httpsCallable(functions, "guruGetOffersEventHandler");
    const offersArrayResponse = JSON.parse((await guruGetOffersEventHandler({ product: product.id })).data);
    console.log('offersArrayResponse >> ', offersArrayResponse);

    if (!offersArrayResponse || offersArrayResponse?.length == 0) {
      throw 'Lista de ofertas está vazia';
    };

    console.log('return >> ', {
      product, offers: offersArrayResponse
    })

    return {
      product,
      offers: offersArrayResponse
    }
  } catch (error) {
    console.error('Erro GET PRODUCT BY ID >> ' + error);
    alert('Esse código de produto não foi encontrado na sua conta da Guru Digital');
    return;
  }
}

export const generateCheckoutUrlWithParams = ({
  urlCheckout,
  name,
  email,
  phone,
  doc,
}) => (
  `${urlCheckout}?name=${name}&email=${email}&phone_number=${phone}&doc=${doc}`
)