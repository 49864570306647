import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { View } from 'react-native';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const spaceBetween = 20;

const Slider = ({ array, cardWidth, component, width }) => {
    const slidesPerView = (width - 64 - spaceBetween) / (cardWidth + spaceBetween);
    const containerIcon = {
        backgroundColor: component.arrowBackgroundColor.lighter,
        padding: '1rem',
        borderRadius: "15px",
        transition: "background-color 0.3s ease",
        color: "#f7f7f7",
    }

    return (
        <div className="container" style={{ paddingBottom: "3rem" }} >
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                slidesPerView={slidesPerView}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                    clickable: true,
                }}
                pagination={{ el: '.swiper-pagination', clickable: true }}
                style={{ height: '100%' }}
            >
                {array.map((card, index) => (
                    <SwiperSlide key={index}>
                        <View style={{ paddingBottom: '3.5rem', height: '100%' }}>
                            {card}
                        </View>
                    </SwiperSlide>
                ))}
                <div className="slider-controler">
                    <div className="swiper-button-prev slider-arrow"
                        style={containerIcon}
                        onMouseEnter={(e) => {
                            e.target.style.backgroundColor = component.arrowBackgroundColor.darker;
                        }}
                        onMouseLeave={(e) => {
                            e.target.style.backgroundColor = component.arrowBackgroundColor.lighter;
                        }}>
                        <ion-icon name="arrow-back-outline"></ion-icon>
                    </div>
                    <div
                        className="swiper-button-next slider-arrow"
                        style={containerIcon}
                        onMouseEnter={(e) => {
                            e.target.style.backgroundColor = component.arrowBackgroundColor.darker;
                        }}
                        onMouseLeave={(e) => {
                            e.target.style.backgroundColor = component.arrowBackgroundColor.lighter;
                        }}
                    >
                        <ion-icon name="arrow-forward-outline"></ion-icon>
                    </div>
                </div>
            </Swiper>
        </div>
    )
}
export default Slider;