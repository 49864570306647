import styled from 'styled-components/native'

export const Avatar = styled.Image`
width: ${({ width }) => width};
height: 100%;
`;

export const HeaderRightSide = styled.View`
    flex-direction: row;
    height: 3rem;
`;

export const HeaderLeftSide = styled.View`
    flex-direction: row;
    align-items: center;
    height: 3rem;
`;

export const Row = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const ViewLogo = styled.View`
    align-items: center;
    height: 3rem;
    padding: 0.2rem;
`;

export const Content = styled.View`
    align-items: center;
    justify-content: center;
    flex-direction: row; 
    padding: 0.5rem 0rem;
`;

