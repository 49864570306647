import React, { useEffect, useState } from "react";
import { ActivityIndicator, Text } from "react-native";
import THEME from "../../../config/theme";
import { firestore } from "../../../services/firebase/index";
import Header from "../../../components/Header";
import {
    setDoc,
    addDoc,
    collection,
    getDocs,
    doc,
    orderBy,
    query,
    where,
    onSnapshot
} from "firebase/firestore";
import DataTable from 'react-data-table-component';
import TouchableText from "../../../components/TouchableText";
import CreateProductModal from "./CreateProductModal";
import EditProductModal from "./EditProductModal";
import { Container, ViewDataTable, ContentIcon } from "./style";
import { Provider } from "react-native-paper";
import Constants from "expo-constants";
const { guruUserToken } = Constants.manifest.extra;

export function Products({ }) {


    const [products, setProducts] = useState([]);
    const [user, setUser] = useState("");
    const [isCreateProductModalVisible, setIsCreateProductModalVisible] = useState(false);
    const [isEditProductModalVisible, setIsEditProductModalVisible] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null); // State to store selected product
    const [loading, setLoading] = useState(true);


    const handleOpenCreateProductModal = () => {
        setIsCreateProductModalVisible(true);
    };




    const handleCloseCreateProductModal = () => {
        setIsCreateProductModalVisible(false);
    };


    const handleOpenEditProductModal = (row) => {
        setSelectedProduct(row);
        setIsEditProductModalVisible(true);
    };




    const handleCloseEditProductModal = () => {
        setIsEditProductModalVisible(false);
    };


    const handleCreateProduct = async (productInfo, offersInfos, productId) => {


        try {
            // Crie um documento na coleção 'products' com as informações fornecidas
            const productRef = await addDoc(collection(firestore, 'products'), {
                name: productInfo.name,
                type: productInfo.type,
                productId: productId,
                marketplace: "Guru Digital",
                archived: true,
            });


            // Crie documentos na subcoleção 'offers' com as informações de cada oferta
            for (const offerInfo of offersInfos.data) {
                await addDoc(collection(productRef, 'offers'), offerInfo);
            }
        } catch (error) {
            console.error('Erro ao criar produto:', error);
            // Adicione lógica de tratamento de erro conforme necessário
        }
    };




    const handleEditProduct = async (productInfo, offersInfos, hasExpirationDate, expirationDays, checkoutUrl, archived, description, selectedProduct) => {
        // Atualizar o documento encontrado com as novas informações
        const productRef = doc(firestore, "products", selectedProduct?.id);


        try {
            // Crie um objeto com as propriedades que não são undefined
            const updateData = {
                marketplace: "Guru Digital",
                name: productInfo?.name,
                productId: productInfo?.marketplace_id,
                type: productInfo?.type,
            };


            // Adicione outras propriedades ao objeto de atualização, se não forem undefined
            if (hasExpirationDate !== undefined) {
                updateData.hasExpirationDate = hasExpirationDate;
            }
            if (expirationDays !== undefined) {
                updateData.days = expirationDays;
            }
            if (checkoutUrl !== undefined) {
                updateData.checkoutUrl = checkoutUrl;
            }
            if (archived !== undefined) {
                updateData.archived = archived;
            }
            if (description !== undefined) {
                updateData.description = description;
            }


            await setDoc(productRef, updateData, { merge: true });


            // Atualize ou adicione documentos de ofertas na subcoleção 'offers'
            const offersCollectionRef = collection(productRef, 'offers');
            const offersSnapshot = await getDocs(offersCollectionRef);


            // Verifique cada oferta no offersInfo.data
            for (const offerInfo of offersInfos.data) {
                const offerId = offerInfo.id;


                // Verifique se a oferta já existe na subcoleção
                const existingOfferDoc = offersSnapshot.docs.find(doc => doc.data().id === offerId);


                if (existingOfferDoc) {
                    // Se a oferta já existir, atualize-a com as novas informações
                    await setDoc(doc(offersCollectionRef, existingOfferDoc.id), offerInfo, { merge: true });
                } else {
                    // Se a oferta não existir, crie um novo documento
                    await addDoc(offersCollectionRef, offerInfo);
                }
            }
        } catch (error) {
            console.error('Erro ao atualizar documento:', error);
            // Adicione lógica de tratamento de erro conforme necessário
        }
    };




    const findAllProducts = async (isAdmin) => {
        const productsRef = collection(firestore, `products`);
        const q = query(productsRef, orderBy("name"));


        const unsubscribe = onSnapshot(q, (snapshot) => {
            const updatedProducts = [];
            snapshot.forEach((doc) => {
                if (doc.data()?.marketplace === "Guru Digital") {
                    updatedProducts.push({ id: doc.id, ...doc.data() });
                }
            });
            setProducts(updatedProducts);
            setLoading(false);
        });


        // Retorna a função de cancelamento para limpar o listener quando necessário
        return unsubscribe;
    };


    useEffect(() => {
        const fetchData = async () => {
            await findAllProducts(user?.isAdmin);
        };


        fetchData();
    }, []);




    if (loading) return (
        <ActivityIndicator
            style={{
                flex: 1,
                backgroundColor: "#FFFFFF",
                justifyContent: "center",
            }}
            color={THEME.COLORS.PRIMARY_900}
            size="large"
        />
    )


    // Definindo as cores de fundo condicionalmente com base na propriedade 'archived' de cada linha
    const conditionalRowStyles = [
        {
            when: row => row.archived !== false,
            style: {
                backgroundColor: '#ffffff' // Vermelho se archived for verdadeiro
            }
        },
        {
            when: row => row.archived === false,
            style: {
                backgroundColor: '#ffffff' // Verde se archived for falso
            }
        }
    ];




    return (
        <Provider>
            <Container>
                <Header navigation={navigation} user={user} />
                {!isCreateProductModalVisible &&
                    <ContentIcon>
                        <TouchableText
                            onPress={() => handleOpenCreateProductModal()}
                            title="+"
                            color="#ffffff"
                            fontFamily={THEME.FONTFAMILY.BOLD}
                            fontSize={THEME.FONTSIZE.BIG}
                            margin="0rem 1rem"

                        ></TouchableText>
                    </ContentIcon>
                }
                {isCreateProductModalVisible &&
                    <CreateProductModal
                        isVisible={isCreateProductModalVisible}
                        onClose={() => {
                            handleCloseCreateProductModal();
                        }}
                        onCreate={handleCreateProduct}
                        user={user}
                    />
                }
                {isEditProductModalVisible &&
                    <EditProductModal
                        isVisible={isEditProductModalVisible}
                        onClose={() => {
                            handleCloseEditProductModal();
                        }}
                        onEdit={handleEditProduct}
                        user={user}
                        selectedProduct={selectedProduct}
                    />
                }
                <ViewDataTable>
                    <Text style={{ fontSize: 24, fontSize: '35px', marginBottom: '16px', marginTop: '16px', fontFamily: THEME.FONTFAMILY.TITLE }}>Produtos</Text>
                    <DataTable
                        style={{ backgroundColor: "#ffffff", borderRadius: 10 }}
                        columns={columns}
                        data={products}
                        highlightOnHover
                        pointerOnHover
                        striped={false} // Define para false para remover as linhas coloridas entre as linhas da tabela
                        customStyles={customStyles}
                        onRowClicked={(row, event) => handleOpenEditProductModal(row)}
                        conditionalRowStyles={conditionalRowStyles} // Aplicando estilos condicionais
                    />
                </ViewDataTable>
            </Container>
        </Provider>
    );
}


const columns = [
    {
        name: 'Nome do Produto',
        selector: row => row.name,
        sortable: true,
    },
    {
        name: 'Código do Produto',
        selector: row => row.productId,
        sortable: true,
    },
    {
        name: 'Tipo',
        selector: row => row.type == "plan" ? "assinatura" : row.type == "product" ? "produto" : row.type,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.archived == true ? "Inativo" : "Ativo",
        sortable: true,
    },
];


const customStyles = {
    rows: {
        style: {
            color: "#757575", // Cor do texto das linhas
            fontFamily: THEME.FONTFAMILY.MEDIUM,
            fontSize: THEME.FONTSIZE.MEDIUM,
            borderTopColor: 'white', // Cor da borda superior da célula
            borderBottomColor: 'white', // Cor da borda inferior da célula
        },
    },
    headCells: {
        style: {
            color: "#000000",
            fontFamily: THEME.FONTFAMILY.MEDIUM,
            fontSize: THEME.FONTSIZE.STANDARD
        },
    },
    cells: {
        style: {
            borderTopColor: 'white', // Cor da borda superior da célula
            borderBottomColor: 'white', // Cor da borda inferior da célula
        },
    },
    table: {
        style: {
            borderCollapse: 'collapse',
            borderRadius: '10px', // Define a borda da tabela levemente arredondada
            overflow: 'hidden'
        }
    },
    dataTable: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', // Sombreamento
        borderRadius: '10px', // Bordas arredondadas
        backgroundColor: '#FAFAFA', // Cor de fundo da tabela
    },
};
