import React, { useState } from "react";
import {
  Container,
  ViewButton,
  ViewText,
  ViewTextInput,
  ViewDescription,
  ViewVideo,
  ContentIcon,
  ContentIconParagraph
} from "./style";
import TextInput from "../TextInput";
import Button from "../Button";
import {
  SubTitle,
  SmallText,
  FooterText,
} from "../../config/theme/globalStyles";
import { MaterialIcons, AntDesign } from "@expo/vector-icons";
import THEME from "../../config/theme";
import { AlertBox } from "../AlertBox";
import { VideoUpload } from "../VideoAudioPicker";
import { Provider } from "react-native-paper";
import { PdfUpload } from "../PdfPicker";
import VideoPlayer from "../VideoPlayer";
import { TouchableOpacity, Modal, View } from "react-native";
import { useNavigation } from "@react-navigation/native";

export function EditVideo(props) {
  const navigation = useNavigation();

  const { video, onSave, isSaving, onClose, categoryId, videoId, cardId, user } = props;

  const videoUrl =
    video?.videoInfo?.url ||
    video?.pandaVideoMetadata?.video_player ||
    video?.link;

  const pdfUrl = video?.pdf?.url || video.pdf;

  const {
    title = "",
    url = "",
    meetingNumber = "",
    meetingPassword = "",
    link = "",
    description = [""]
  } = video || {};

  const [updatedVideo, setUpdatedVideo] = useState({
    title,
    url,
    meetingNumber,
    meetingPassword,
    link,
    description,
  });

  // Video Upload
  const [showVideoUploadModal, setShowVideoUploadModal] = useState(false);
  // PDF Upload
  const [showPDFUploadModal, setShowPDFUploadModal] = useState(false);

  const [showSaveSuccessAlert, setShowSaveSuccessAlert] = useState(false);

  const handleOnSaveClick = async () => {
    await onSave(updatedVideo);
    setShowSaveSuccessAlert(true);
  };

  return (
    <Provider>
      <Container>
        <SubTitle
          padding="0rem 0rem 0.5rem 0rem"
          color={THEME.COLORS.TEXT_ABOUT}
        >
          Alterar Informações:
        </SubTitle>
        <ViewDescription>
          <SmallText textAlign="flex-start" color={THEME.COLORS.TEXT_ABOUT}>
            1. Título:
          </SmallText>
        </ViewDescription>
        <ViewTextInput>
          <TextInput
            placeholder="Digitar título"
            keyboardType="default"
            returnKeyType="go"
            value={updatedVideo.title}
            onChange={({ target: { value } }) => {
              setUpdatedVideo(() => ({ ...updatedVideo, title: value }));
            }}
          />
        </ViewTextInput>
        <ViewDescription>
          <SmallText textAlign="flex-start" color={THEME.COLORS.TEXT_ABOUT}>
            2. Descrição:
          </SmallText>
        </ViewDescription>
        {Array.isArray(description) && description.map((item, index) => (
          <ViewTextInput>
            <TextInput
              placeholder="Digitar descrição"
              keyboardType="default"
              returnKeyType="go"
              value={item}
              onChange={({ target: { value } }) => {
                description[index] = value
                setUpdatedVideo(() => ({ ...updatedVideo, description: description }));
              }}
            />
          </ViewTextInput>
        ))}
        <View
              style={{ flexDirection: "row", justifyContent: "space-evenly" }}
            >
              <ContentIconParagraph onPress={() => {
                description.push("")
                setUpdatedVideo(() => ({ ...updatedVideo, description: description }));
              }}>
                <AntDesign
                  name="plus"
                  size={18}
                  color={THEME.COLORS.TEXT_ABOUT}
                />
                <FooterText color={THEME.COLORS.TEXT_ABOUT} padding="0.5rem">
                  Adicionar parágrafo
                </FooterText>
              </ContentIconParagraph>
              <ContentIconParagraph onPress={() => {
                description.pop()
                setUpdatedVideo(() => ({ ...updatedVideo, description: description }));
              }}>
                <AntDesign
                  name="minus"
                  size={18}
                  color={THEME.COLORS.TEXT_ABOUT}
                />
                <FooterText color={THEME.COLORS.TEXT_ABOUT} padding="0.5rem">
                  Remover parágrafo
                </FooterText>
              </ContentIconParagraph>
            </View>
        {video.type === "live" ? (
          <>
            <ViewDescription>
              <SmallText
                textAlign="flex-start"
                color={THEME.COLORS.TEXT_ABOUT}
              >
                3. ID da Reunião do Zoom:
              </SmallText>
            </ViewDescription>
            <ViewTextInput>
              <TextInput
                placeholder="Digitar ID"
                keyboardType="default"
                returnKeyType="go"
                value={updatedVideo.meetingNumber}
                onChange={({ target: { value } }) => {
                  setUpdatedVideo(() => ({
                    ...updatedVideo,
                    meetingNumber: value,
                  }));
                }}
              />
            </ViewTextInput>
            <ViewDescription>
              <SmallText
                textAlign="flex-start"
                color={THEME.COLORS.TEXT_ABOUT}
              >
                4. Password do link da Reunião do Zoom:
              </SmallText>
            </ViewDescription>
            <ViewTextInput>
              <TextInput
                placeholder="Digitar o código após o 'pwd=' do link"
                keyboardType="default"
                returnKeyType="go"
                value={updatedVideo.meetingPassword}
                onChange={({ target: { value } }) => {
                  setUpdatedVideo(() => ({
                    ...updatedVideo,
                    meetingPassword: value,
                  }));
                }}
              />
            </ViewTextInput>
            <ViewDescription>
              <SmallText
                textAlign="flex-start"
                color={THEME.COLORS.TEXT_ABOUT}
              >
                (Opcional) Adicione um conteúdo em PDF:
              </SmallText>
            </ViewDescription>
            <ContentIcon onPress={() => setShowPDFUploadModal(true)}>
              <MaterialIcons
                name="drive-folder-upload"
                size={24}
                color={THEME.COLORS.TEXT_ABOUT}
              />
              <FooterText color={THEME.COLORS.TEXT_ABOUT} padding="0.5rem">
                Upload PDF
              </FooterText>
            </ContentIcon>
            {pdfUrl ? (
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate("PDF", {
                    pdf: pdfUrl,
                  });
                }}
              >
                <FooterText
                  color={THEME.COLORS.TEXT_ABOUT}
                  padding="0.5rem"
                  textDecoration="underline"
                >
                  Visualizar PDF
                </FooterText>
              </TouchableOpacity>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <ViewDescription>
              <SmallText
                textAlign="flex-start"
                color={THEME.COLORS.TEXT_ABOUT}
              >
                3. Vídeo ou áudio:
              </SmallText>
            </ViewDescription>
            <ViewDescription>
              <SmallText
                textAlign="flex-start"
                color={THEME.COLORS.TEXT_ABOUT}
              >
                - Link do Vídeo/Áudio do Youtube ou Vimeo:
              </SmallText>
            </ViewDescription>
            <ViewTextInput>
              <ViewText>
                <TextInput
                  placeholder="Colar link"
                  keyboardType="default"
                  returnKeyType="go"
                  value={updatedVideo?.link}
                  onChange={({ target: { value } }) => {
                    setUpdatedVideo(() => ({
                      ...updatedVideo,
                      link: value,
                    }));
                  }}
                />
              </ViewText>
            </ViewTextInput>
            {props.planType === "growth" || props.planType === "premium" ? (
              <>
                <SmallText textAlign="center" color={THEME.COLORS.TEXT_ABOUT}>
                  OU
                </SmallText>
                <SmallText
                  textAlign="flex-start"
                  color={THEME.COLORS.TEXT_ABOUT}
                >
                  - Adicione um vídeo/áudio no banco de dados:
                </SmallText>
                <ContentIcon onPress={() => setShowVideoUploadModal(true)}>
                  <MaterialIcons
                    name="drive-folder-upload"
                    size={24}
                    color={THEME.COLORS.TEXT_ABOUT}
                  />
                  <FooterText
                    color={THEME.COLORS.TEXT_ABOUT}
                    padding="0.5rem"
                  >
                    Upload Vídeo/Áudio
                  </FooterText>
                </ContentIcon>
              </>
            ) : (
              <></>
            )}
            {videoUrl && (
              <ViewVideo>
                <VideoPlayer video={video} />
              </ViewVideo>
            )}
            <ViewDescription>
              <SmallText
                textAlign="flex-start"
                color={THEME.COLORS.TEXT_ABOUT}
              >
                3. (Opcional) Adicione um conteúdo em PDF:
              </SmallText>
            </ViewDescription>
            <ContentIcon onPress={() => setShowPDFUploadModal(true)}>
              <MaterialIcons
                name="drive-folder-upload"
                size={24}
                color={THEME.COLORS.TEXT_ABOUT}
              />
              <FooterText color={THEME.COLORS.TEXT_ABOUT} padding="0.5rem">
                Upload PDF
              </FooterText>
            </ContentIcon>
            {pdfUrl ? (
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate("PDF", {
                    pdf: pdfUrl,
                  });
                }}
              >
                <FooterText
                  color={THEME.COLORS.TEXT_ABOUT}
                  padding="0.5rem"
                  textDecoration="underline"
                >
                  Visualizar PDF
                </FooterText>
              </TouchableOpacity>
            ) : (
              <></>
            )}
            <Modal
              animationType="fade"
              transparent={true}
              visible={showVideoUploadModal}
              onRequestClose={() => { setShowVideoUploadModal(false) }}
            >
              <VideoUpload
                url={videoUrl}
                onUploadCompleted={() => setShowVideoUploadModal(true)}
                onClose={() => setShowVideoUploadModal(false)}
                metadata={{
                  databasePath: `/categories/${categoryId}/cards/${cardId}/videos/${videoId}`,
                  databasePropName: "videoInfo"
                }}
                user={user}
              ></VideoUpload>
            </Modal>
          </>
        )}
        <ViewDescription>
          <SmallText textAlign="flex-start" color={THEME.COLORS.TEXT_ABOUT}>
            (Opcional) Link de acesso externo:
          </SmallText>
          <FooterText textAlign="flex-start" color={THEME.COLORS.TEXT_ABOUT}>
            Aqui você pode colocar um link de acesso externo. Colocar o link com "https://"
          </FooterText>
        </ViewDescription>
        <ViewTextInput>
          <ViewText>
            <TextInput
              placeholder="Colar link"
              keyboardType="default"
              returnKeyType="go"
              value={updatedVideo.url}
              onChange={({ target: { value } }) => {
                setUpdatedVideo(() => ({
                  ...updatedVideo,
                  url: value,
                }));
              }}
            />
          </ViewText>
        </ViewTextInput>
        <AlertBox
          message={"Alteração feita com sucesso! :)"}
          visible={showSaveSuccessAlert}
          leftButtonFunction={onClose}
          onClose={onClose}
          leftButton={"OK"}
        />
        <Modal
          animationType="fade"
          transparent={true}
          visible={showPDFUploadModal}
          onRequestClose={() => { setShowPDFUploadModal(false) }}
        >
          <PdfUpload
            onUploadCompleted={() => setShowPDFUploadModal(true)}
            onClose={() => setShowPDFUploadModal(false)}
            metadata={{
              databasePath: `/categories/${categoryId}/cards/${cardId}/videos/${videoId}`,
              databasePropName: "pdf",
            }}
          ></PdfUpload>
        </Modal>
        <ViewButton>
          <Button
            title={"Salvar"}
            isLoading={isSaving}
            onPress={handleOnSaveClick}
            margin="0rem 0.5rem"
          ></Button>
          <Button
            title={"Cancelar"}
            onPress={onClose}
            margin="0rem 0.5rem"
          ></Button>
        </ViewButton>
      </Container>
    </Provider>
  );
}